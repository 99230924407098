import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as moment from 'moment'
import 'moment/locale/pl'
import { Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
const isEqual = require('react-fast-compare')

const useStyles = makeStyles(theme => ({
    datetimepicker: {
        margin: 0,
    },
}))

function PanelDescription({
    quiz_name,
    setQuizName,
    messages,
    handleMessagesChange,
    addMessage,
    deleteMessage,
    final_date,
    setFinalDate,
}) {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    id="quiz_name"
                    name="quiz_name"
                    value={quiz_name}
                    onChange={e => setQuizName(e.target.value)}
                    label="Tytuł testu"
                    helperText="Wprowadź tytuł testu."
                />
            </Grid>
            {messages.map((m, i) => (
                <Grid item container spacing={2} xs={12} key={`messages-${i}`}>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            id={`message-${i}`}
                            name="message"
                            label={`Wiadomosc wyświetlana od co najmniej ${m.min_points}pkt`}
                            value={m.message}
                            onChange={handleMessagesChange(i)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            id={`min_points-${i}`}
                            name="min_points"
                            type="number"
                            label={`Ilość punktów`}
                            value={m.min_points}
                            onChange={handleMessagesChange(i)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="usuń wiadomość"
                            onClick={deleteMessage(i)}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Button variant="outlined" fullWidth onClick={addMessage}>
                        Dodaj nową wiadomość
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={'pl'}
                >
                    <DateTimePicker
                        variant="dialog"
                        cancelLabel="Anuluj"
                        showTodayButton
                        todayLabel="Dzisiejsza data"
                        ampm={false}
                        label="Data napisania testu"
                        className={classes.datetimepicker}
                        value={final_date}
                        onChange={date => setFinalDate(date)}
                        labelFunc={date =>
                            moment(date, moment.ISO_8601).format('LLL')
                        }
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </React.Fragment>
    )
}

function arePropsEqual(p, n) {
    return (
        isEqual(p.quiz_name, n.quiz_name) &&
        isEqual(p.messages, n.messages) &&
        isEqual(p.final_date, n.final_date)
    )
}

export default React.memo(PanelDescription, arePropsEqual)
