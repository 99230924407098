import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'

const StudyYear = props => {
    const [studyYear, setStudyYear] = React.useState(2020)

    const handleChange = event => {
        setStudyYear(event.target.value)
        props.onChange('study_year')(event)
    }
    const years = Array.apply(null, { length: 10 }).map(function(value, index) {
        return parseInt(new Date().getFullYear()) - index
    })

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="study_year">
                        Rok rozpoczęcia studiów
                    </InputLabel>
                    <Select
                        native
                        required
                        value={studyYear}
                        inputProps={{
                            name: 'study_year',
                            id: 'study_year',
                        }}
                        onChange={handleChange}
                    >
                        {years.map(year => (
                            <option value={year} key={'study_year_' + year}>
                                {year}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </React.Fragment>
    )
}
const MemoStudyYear = React.memo(StudyYear)

export default MemoStudyYear
