const endpoints = {
    quizList: '/quizzes/all',
    quiz: id => `/quizzes/${id}`,
    quizResult: id => `/quizzes/result/${id}`,
    quizResponse: `/quizzes/result`,
}
export const QuizApiProvider = Api => {
    const getAvailableQuizzes = async () => await Api.get(endpoints.quizList)

    const getQuiz = async quizId => await Api.get(endpoints.quiz(quizId))

    const addQuizResponse = async data =>
        await Api.post(endpoints.quizResponse, data)

    const getQuizResult = async quizId => await Api.post(endpoints.quiz(quizId))

    return {
        getAvailableQuizzes,
        getQuiz,
        addQuizResponse,
        getQuizResult,
    }
}
