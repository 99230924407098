import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './context/auth'
import { isAdminToken } from 'services/utils'

function AdminRoute({ component: Component, ...rest }) {
    const { authTokens } = useAuth()

    return (
        <Route
            {...rest}
            render={props =>
                authTokens && isAdminToken(authTokens) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    )
}

export default AdminRoute
