export function jwt_decode(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

export function isAdminToken(authTokens) {
    if (authTokens && authTokens.access_token) {
        const tokenObj = jwt_decode(authTokens.access_token)
        if (tokenObj.role > 0) {
            return true
        }
    }
    return false
}
