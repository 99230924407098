import React from 'react'
import styled from 'styled-components'

import logo from './ateneum-logo.jpg'

const Image = styled.img`
    display: block;
    width: auto;
    height: 60px;
    filter: grayscale(100);
    @media (min-width: 768px) {
        height: calc(90px + 1vw);
    }
`

const HeaderLogo = props => <Image src={logo} />
export default HeaderLogo
