import React from 'react'
import AdminNav from './AdminNav'
import StudentNav from './StudentNav'
import { isAdminToken } from 'services/utils'

const GetNavForRole = ({ tokens, logout }) =>
    isAdminToken(tokens) ? (
        <AdminNav logout={logout} />
    ) : (
        <StudentNav logout={logout} />
    )

export default GetNavForRole
