import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        padding: theme.spacing(2, 4, 3),
    },
    btnPrimary: {
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
}))

export default function ConfirmModal({ open, setOpen, setConfirm }) {
    const classes = useStyles()

    const handleClose = () => {
        setOpen(false)
    }

    const handleAgree = () => {
        setConfirm(true)
        setOpen(false)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card className={classes.card}>
                    <CardContent>
                        <h2 id="transition-modal-title">Zakończ test</h2>
                        <p id="transition-modal-description">
                            Czy jesteś pewien swoich odpowiedzi? Wprowadzone
                            odpowiedzi są ostateczne.
                        </p>
                    </CardContent>

                    <CardActions>
                        <Button
                            onClick={handleAgree}
                            className={classes.btnPrimary}
                        >
                            Potwierdzam
                        </Button>
                        <Button onClick={handleClose}>
                            Muszę się zastanowić
                        </Button>
                    </CardActions>
                </Card>
            </Fade>
        </Modal>
    )
}
