import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const Form = styled.form`
    & > * {
        margin-bottom: 15px !important;
    }
`

export const Input = styled.input`
    padding: 4px 10px;
    border: 2px solid #dedede;
    background: #fff;
`

export const Label = styled.label`
    font-size: 1.3em;
    font-family: 'Bree Serif', serif;
    font-weight: 'regular';
    color: rgba(76, 76, 76, 0.7);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const useStyles = makeStyles(theme => ({
    heading2: {
        marginBottom: '55px',
        [theme.breakpoints.up('md')]: {
            textAlign: props => (props.align === 'right' ? 'right' : 'left'),
        },
    },
}))

export function Heading2({ children, ...props }) {
    const classes = useStyles(props)
    return <h2 className={classes.heading2}>{props.children}</h2>
}

export const Heading3 = styled.h3`
    font-family: 'Bree Serif', 'Times New Roman', serif;
    font-size: 2rem;
    color: #4c4c4c;
    margin: 15px 15px 55px 15px;
    @media (min-width: 768px) {
        text-align: ${props => props.align || 'left'};
    }
`
