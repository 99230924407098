import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import RegisterContext from '../useRegister'
import DepartmentCheckbox from './DepartmentCheckbox'
import StudyYear from './StudyYear'

const useStyles = makeStyles(theme => ({
    push: {
        marginTop: '40px',
    },
}))

function StudentDetails(props) {
    const classes = useStyles()

    return (
        <RegisterContext.Consumer>
            {({ state, errors, handleRegisterChange }) => (
                <React.Fragment>
                    <Grid item xs={12} className={classes.push}>
                        <TextField
                            fullWidth
                            id="album_number"
                            type="number"
                            label="Numer Albumu"
                            error={errors.album_number !== ''}
                            helperText={errors.album_number}
                            value={state.album_number}
                            onChange={handleRegisterChange('album_number')}
                            className={classes.formControl}
                        />
                    </Grid>
                    <StudyYear
                        value={state.study_year}
                        onChange={handleRegisterChange}
                    />
                    <DepartmentCheckbox onChange={handleRegisterChange} />
                </React.Fragment>
            )}
        </RegisterContext.Consumer>
    )
}
function StudentDetailsAreEqual(prevState, nextState) {
    return (
        prevState.album_number === nextState.album_number &&
        prevState.study_year === nextState.study_year
    )
}

const MemoStudentDetails = React.memo(StudentDetails, StudentDetailsAreEqual)
export default MemoStudentDetails
