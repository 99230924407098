import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'

import Pagination from '@material-ui/lab/Pagination'
import Slide from '@material-ui/core/Slide'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Chip from '@material-ui/core/Chip'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { Heading2 } from 'scenes/home/components'
import EditButtons from 'scenes/admin/components/EditButtons'
import SearchFields from 'scenes/admin/components/SearchFields'
import * as moment from 'moment'
import 'moment/locale/pl'

import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { AdminApiProvider } from 'services/admin'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
        backgroundColor: '#eed433',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            width: 'max-content',
            margin: `${theme.spacing(2)}px auto`,
        },
    },
    table: {
        minWidth: 650,
    },
    labelWidth: { width: 120 },
    zoomCol: {
        width: 0,
    },
    deps: {
        '& > *': {
            color: theme.palette.text.primary,
            margin: theme.spacing(0.5, 1, 0, 0),
            border:
                theme.palette.type === 'dark'
                    ? `1px solid ${theme.palette.text.disabled}`
                    : 'none',
        },
    },
    chipRed: {
        color: '#fff',
        backgroundColor: '#ff0000',
    },

    btnRed: {
        color: '#e53935',
    },

    skeletonContainer: {
        height: 640,
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    searchBar: {
        marginBottom: theme.spacing(1),
    },
    tableBody: {
        overflowX: 'auto',
        width: 300,
    },
    alert: {
        margin: `${theme.spacing(2)}px auto`,
    },
    searchButton: { backgroundColor: '#2376b7' },
    searchBar: { marginBottom: theme.spacing(2) },
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

function applySearch(row, search) {
    let on = false
    const values = [
        row['album_number'].toString(),
        row['creation_date'],
        row['email_address'],
    ]

    for (const v of values) {
        if (v.match(search)) {
            on = true
            break
        }
    }

    return on
}

const headCells = [
    {
        id: 'album_number',
        label: 'Nr albumu',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'email_address',
        label: 'Adres email',
        numeric: false,
        disablePadding: false,
    },
    {
        id: 'department_name',
        label: 'Wydział(y)',
        numeric: true,
    },
    {
        id: 'creation_date',
        label: 'Data utworzenia konta',
        numeric: true,
        disablePadding: false,
    },
    {
        id: 'is_email_confirmed',
        label: 'Email potwierdzony',
        numeric: false,
        disablePadding: false,
    },
    {
        id: 'is_active',
        label: 'Konto aktywne',
        numeric: false,
        disablePadding: false,
    },
]

function StudentsTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        numSelected,
        rowCount,
        editMode,
        onRequestSort,
        onSelectAllClick,
    } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <Slide
                    in={editMode}
                    direction="right"
                    mountOnEnter
                    unmountOnExit
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount
                            }
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'Zaznacz wszystkich studentów',
                            }}
                            color="primary"
                        />
                    </TableCell>
                </Slide>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

function StudentsTable({
    rows,
    search,
    searchExp,
    classes,
    checked,
    handleCheckbox,
    order,
    orderBy,
    count,
    editMode,
    switchUserIsActive,
    onRequestSort,
    onSelectAllClick,
}) {
    return (
        <React.Fragment>
            <TableContainer component={Paper} variant="outlined">
                <Table className={classes.table} aria-label="simple table">
                    <StudentsTableHead
                        editMode={editMode}
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        numSelected={checked.filter(e => e).length}
                        rowCount={count}
                        onRequestSort={onRequestSort}
                        onSelectAllClick={onSelectAllClick}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .filter(row =>
                                search === ''
                                    ? true
                                    : applySearch(row, searchExp)
                            )
                            .map(row => (
                                <TableRow key={row.album_number}>
                                    <Slide
                                        in={editMode}
                                        direction="right"
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={checked[row.index]}
                                                onChange={handleCheckbox(
                                                    row.index
                                                )}
                                                color="primary"
                                            />
                                        </TableCell>
                                    </Slide>
                                    <TableCell padding="none" align="right">
                                        {row.album_number}
                                    </TableCell>
                                    <TableCell>{row.email_address}</TableCell>
                                    <TableCell
                                        align="right"
                                        className={classes.deps}
                                    >
                                        {row.department_name.map((dep, k) => (
                                            <Chip
                                                key={`chip-${row.index}-${k}`}
                                                size="small"
                                                color="secondary"
                                                label={dep}
                                            />
                                        ))}
                                    </TableCell>
                                    <TableCell align="right">
                                        {moment(
                                            row.creation_date,
                                            moment.ISO_8601
                                        ).format('LLL')}
                                    </TableCell>

                                    <TableCell>
                                        {row.is_email_confirmed ? (
                                            <Chip
                                                size="small"
                                                color="primary"
                                                label={'Potwierdzony'}
                                            />
                                        ) : (
                                            <Chip
                                                disabled
                                                size="small"
                                                color="secondary"
                                                classes={{
                                                    colorSecondary:
                                                        classes.chipRed,
                                                }}
                                                label={'Niepotwierdzony'}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {editMode ? (
                                            row.is_active ? (
                                                <Button
                                                    size="small"
                                                    color="secondary"
                                                    classes={{
                                                        textSecondary:
                                                            classes.btnRed,
                                                    }}
                                                    onClick={() => {
                                                        switchUserIsActive(
                                                            row.user_id
                                                        )
                                                    }}
                                                >
                                                    Dezaktywuj
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                        switchUserIsActive(
                                                            row.user_id
                                                        )
                                                    }}
                                                >
                                                    Aktywuj
                                                </Button>
                                            )
                                        ) : row.is_active ? (
                                            <Chip
                                                size="small"
                                                color="primary"
                                                label={'Aktywne'}
                                            />
                                        ) : (
                                            <Chip
                                                disabled
                                                size="small"
                                                color="secondary"
                                                label={'Nieaktywne'}
                                                classes={{
                                                    colorSecondary:
                                                        classes.chipRed,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

function mapDepartmentNumToName(department) {
    switch (department) {
        case 0:
            return 'Neofilologiczny'
        case 1:
            return 'Studiów Edukacyjnych'
        case 2:
            return 'Nauk Społecznych'
        default:
            return ''
    }
}

function StudentsList() {
    const classes = useStyles()
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)
    const [studentsList, setStudentsList] = React.useState(null)
    const [editMode, setEditMode] = React.useState(false)
    const [page, setPage] = React.useState(1)
    const [maxPage, setMaxPage] = React.useState(4)
    const [limit] = React.useState(50)
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('creation_date')
    const [count, setCount] = React.useState(1)
    const [searchAlbum, setSearchAlbum] = React.useState('')
    const [searchEmail, setSearchEmail] = React.useState('')
    const [startDate, setStartDate] = React.useState('')
    const [endDate, setEndDate] = React.useState('')
    const [checkedStudents, setCheckedStudents] = React.useState([])

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleSearch = e => {
        if (e.target.name === 'searchAlbum') {
            setSearchAlbum(e.target.value)
        } else if (e.target.name === 'searchEmail') {
            setSearchEmail(e.target.value)
        } else if (e.target.name === 'startDate') {
            setStartDate(e.target.value)
        } else if (e.target.name === 'endDate') {
            setEndDate(e.target.value)
        }
    }

    const resetField = field => {
        if (field === 'searchAlbum') {
            setSearchAlbum('')
        } else if (field === 'searchEmail') {
            setSearchEmail('')
        } else if (field === 'startDate') {
            setStartDate('')
        } else if (field === 'endDate') {
            setEndDate('')
        }
        handleRefresh()
    }

    const handleResetAll = () => {
        setSearchAlbum('')
        setSearchEmail('')
        setStartDate('')
        setEndDate('')
        populateStudentsList()
    }

    const handleCheckbox = i => e => {
        let c = checkedStudents.slice()
        c.splice(i, 1, !checkedStudents[i])
        setCheckedStudents(c)
    }

    const getCheckedStudents = () => {
        return checkedStudents
            .slice()
            .map((el, i) => (el ? studentsList[i].user_id : el))
            .filter(el => el)
    }

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    const deleteStudent = async user_id => {
        await AdminApiProvider(ApiContext).user_admin_delete(user_id)
    }

    const activateStudentArray = async user_id_array => {
        await AdminApiProvider(ApiContext).users_admin_activate(user_id_array)
    }

    const deactivateStudentArray = async user_id_array => {
        await AdminApiProvider(ApiContext).users_admin_deactivate(user_id_array)
    }

    const populateStudentsList = async () => {
        const skip = limit * (page - 1)
        const res = await AdminApiProvider(ApiContext).getStudentsList(
            skip,
            limit,
            searchAlbum,
            searchEmail,
            startDate,
            endDate
        )
        setMaxPage(parseInt((res.data.count + limit - 1) / limit))
        let s = res.data.data.map((el, i) => ({
            ...el,
            department_name: el.department_name.map(mapDepartmentNumToName),
            index: i,
            creation_date: el.creation_date.slice(0, 19),
        }))
        setCheckedStudents(s.map(e => false))
        setCount(parseInt(res.data.count))
        setStudentsList(s)
    }
    const switchUserIsActive = async user_id => {
        await AdminApiProvider(ApiContext).switchUserIsActive(user_id)

        let s = studentsList.slice().map(el => {
            return el.user_id === user_id
                ? {
                      ...el,
                      is_active: !el.is_active,
                  }
                : el
        })

        setStudentsList(s)
    }

    const makeApiCall = func => async (...args) => {
        try {
            await func(...args)
        } catch (e) {
            console.log({ e })
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    React.useEffect(() => {
        makeApiCall(populateStudentsList)()
    }, [page])

    const handleRefresh = () => {
        setPage(1)
        makeApiCall(populateStudentsList)()
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            setCheckedStudents(studentsList.map(n => true))
            return
        }
        setCheckedStudents(studentsList.map(n => false))
    }

    const toggleEditMode = () => {
        setEditMode(!editMode)
    }

    const handleDeleteUsers = async () => {
        console.log('Deleting students: ')
        getCheckedStudents().forEach(stud => console.log(stud))
        await Promise.all(
            getCheckedStudents().map(async studentId =>
                makeApiCall(deleteStudent)(studentId)
            )
        )
        console.log('Students deleted')
        makeApiCall(populateStudentsList)()
    }

    const handleActivateStudents = async () => {
        await makeApiCall(activateStudentArray)(getCheckedStudents())
        makeApiCall(populateStudentsList)()
    }

    const handleDeactivateStudents = async () => {
        await makeApiCall(deactivateStudentArray)(getCheckedStudents())
        makeApiCall(populateStudentsList)()
    }

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    return (
        <React.Fragment>
            <Heading2>Zarejestrowani Studenci</Heading2>

            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>

            {studentsList ? (
                <React.Fragment>
                    <SearchFields
                        fields={[
                            {
                                label: 'Nr albumu',
                                name: 'searchAlbum',
                                value: searchAlbum,
                                type: 'text',
                            },
                            {
                                label: 'Email',
                                name: 'searchEmail',
                                value: searchEmail,
                                type: 'text',
                            },
                            {
                                label: 'Data początkowa',
                                name: 'startDate',
                                value: startDate,
                                type: 'date',
                            },
                            {
                                label: 'Data końcowa',
                                name: 'endDate',
                                value: endDate,
                                type: 'date',
                            },
                        ]}
                        resetField={resetField}
                        handleChange={handleSearch}
                        handleSearch={handleRefresh}
                        resetAll={handleResetAll}
                    />
                    <EditButtons
                        classes={classes}
                        refresh={handleRefresh}
                        editMode={editMode}
                        toggleEditMode={toggleEditMode}
                        handleActivateStudents={handleActivateStudents}
                        handleDeactivateStudents={handleDeactivateStudents}
                        handleDeleteUsers={handleDeleteUsers}
                    />

                    <StudentsTable
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        rows={studentsList}
                        classes={classes}
                        count={count}
                        editMode={editMode}
                        checked={checkedStudents}
                        handleCheckbox={handleCheckbox}
                        switchUserIsActive={makeApiCall(switchUserIsActive)}
                    />
                    {maxPage > 1 && (
                        <div className={classes.pagination}>
                            <Pagination
                                count={maxPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className={classes.skeletonContainer}>
                    <Skeleton variant="text" width={440} />
                    <Skeleton variant="rect" height={80} />
                    <Skeleton variant="rect" height={450} />
                </div>
            )}
        </React.Fragment>
    )
}

export default StudentsList
