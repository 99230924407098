import React from 'react'
import Button from '@material-ui/core/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

function AddQuestionButton(props) {
    return (
        <Button fullWidth variant="outlined" color="primary" {...props}>
            <AddOutlinedIcon />
            Dodaj kolejne pytanie
        </Button>
    )
}

export default AddQuestionButton
