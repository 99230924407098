import React from 'react'
import Button from '@material-ui/core/Button'

function EditButtons({
    classes,
    editMode,
    toggleEditMode,
    handleActivateStudents,
    handleDeactivateStudents,
    handleDeleteUsers,
}) {
    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={toggleEditMode}
                classes={{
                    label: classes.labelWidth,
                }}
            >
                {editMode ? 'Wróć' : 'Tryb edycji'}
            </Button>
            <Button
                disabled={!editMode}
                color="primary"
                onClick={handleActivateStudents}
            >
                Aktywuj zaznaczone
            </Button>
            <Button
                disabled={!editMode}
                color="secondary"
                classes={{
                    textSecondary: classes.btnRed,
                }}
                onClick={handleDeactivateStudents}
            >
                Dezaktywuj zaznaczone
            </Button>
            <Button
                disabled={!editMode}
                color="secondary"
                classes={{
                    textSecondary: classes.btnRed,
                }}
                onClick={handleDeleteUsers}
            >
                Usuń zaznaczone
            </Button>
        </React.Fragment>
    )
}

export default EditButtons
