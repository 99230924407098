import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MaterialLink from '@material-ui/core/Link'
import { Heading2 } from 'scenes/home/components'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Pagination from '@material-ui/lab/Pagination'
import Skeleton from '@material-ui/lab/Skeleton'
import * as moment from 'moment'
import 'moment/locale/pl'

import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { AdminApiProvider } from 'services/admin'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            width: 'max-content',
            margin: `${theme.spacing(2)}px auto`,
        },
    },
    table: {
        minHeight: 100,
    },
    skeletonContainer: {
        height: 640,
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    alert: {
        margin: `${theme.spacing(2)}px auto`,
    },
}))

function CreateRapport(props) {
    const classes = useStyles()
    const [quizData, setQuizData] = useState(null)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(4)
    const [limit] = useState(10)
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)
    const handlePageChange = (event, value) => {
        setPage(value)
    }
    const [datetime, setDateTime] = useState(new Date('2014-08-18T21:11:54'))
    const updateResults = async () => {
        try {
            const skip = limit * (page - 1)
            const res = await AdminApiProvider(ApiContext).getTestResults(
                skip,
                limit
            )
            setMaxPage(parseInt((res.data.count + limit - 1) / limit))
            setQuizData(res.data.data)
        } catch (e) {
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    const handleDateChange = quiz_id => date => {
        try {
            let quiz = null
            const s = quizData.slice().map(el => {
                if (el.quiz_id === quiz_id) {
                    quiz = {
                        ...el,
                        fill_time: date, //d.toISOString()
                    }
                    return quiz
                } else {
                    return el
                }
            })
            if (quiz) {
                AdminApiProvider(ApiContext).updateExistingTest(
                    quiz.quiz_id,
                    quiz
                )
                setQuizData(s)
            }
        } catch (e) {
            setToastSeverity('error')
            updateResults()
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    React.useEffect(() => {
        updateResults()
    }, [page])

    return (
        <React.Fragment>
            <Heading2>Stwórz raport</Heading2>

            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>
            {quizData ? (
                <React.Fragment>
                    <TableContainer component={Paper} variant="outlined">
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tytuł</TableCell>
                                    <TableCell>Czas</TableCell>
                                    <TableCell>Nr Albumu</TableCell>
                                    <TableCell align="right">Wynik</TableCell>
                                    <TableCell align="right">
                                        Link do raportu
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quizData.map((row, i) => (
                                    <TableRow
                                        key={`${i}_${row.title}-${row.quiz_fill_time}`}
                                    >
                                        <TableCell>{row.quiz_name}</TableCell>
                                        <TableCell>
                                            <MuiPickersUtilsProvider
                                                libInstance={moment}
                                                utils={MomentUtils}
                                                locale={'pl'}
                                            >
                                                <DateTimePicker
                                                    variant="dialog"
                                                    cancelLabel="Anuluj"
                                                    showTodayButton
                                                    todayLabel="Dzisiejsza data"
                                                    ampm={false}
                                                    label="Data napisania testu"
                                                    value={row.quiz_fill_time}
                                                    onChange={handleDateChange(
                                                        row.quiz_id
                                                    )}
                                                    labelFunc={date =>
                                                        moment(
                                                            date,
                                                            moment.ISO_8601
                                                        ).format('LLL')
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell>
                                            {row.user_album_number}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.quiz_result_percentage}%
                                        </TableCell>
                                        <TableCell align="right">
                                            <MaterialLink
                                                color="primary"
                                                component={Link}
                                                to={
                                                    '/static/' +
                                                    row.link_to_rapport
                                                }
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                Link
                                            </MaterialLink>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {maxPage > 1 && (
                        <div className={classes.pagination}>
                            <Pagination
                                count={maxPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className={classes.skeletonContainer}>
                    <Skeleton variant="rect" height={80} />
                    <Skeleton variant="rect" height={450} />
                </div>
            )}
        </React.Fragment>
    )
}

export default CreateRapport
