import React from 'react'
import { useHistory } from 'react-router-dom'
/* components */
import Link from '@material-ui/core/Link'

import Heading from 'components/typography/Heading'
import { Button } from 'components/buttons/Button'

import BasicInputs from './components/BasicInputs'
import StudentDetails from './components/StudentDetails'

/* material ui */
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Api } from 'services/api'
import { AuthApiProvider } from 'services/auth'
import Checkbox from '@material-ui/core/Checkbox'
import RegisterContext from './useRegister'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: '15px auto',
        width: props => (props.normalScreen ? '50%' : '85%'),
        flexBasis: props => (props.normalScreen ? '50%' : '85%'),
        maxWidth: props => (props.normalScreen ? '50%' : '85%'),
    },
    alert: {
        marginTop: 15,
    },
}))

function RegisterView() {
    const theme = useTheme()
    const normalScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles({ normalScreen })
    const history = useHistory()

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setToastOpen(false)
    }
    const [state, setState] = React.useState({
        email_address: '',
        password: '',
        password_repeat: '',
        album_number: '',
        study_year: 2020,
        department_name: ['0'],
        accepted: false,
    })
    const handleRegisterChange = name => event => {
        setState({ ...state, [name]: event.target.value })
    }

    const handleAccept = () => {
        setState({ ...state, accepted: !state.accepted })
    }

    const [validationError, setValidationError] = React.useState({
        email_address: '',
        password: '',
        password_repeat: '',
        album_number: '',
        study_year: '',
        department_name: '',
        accepted: '',
    })
    const [greedyCheck, setGreedyCheck] = React.useState(false)

    const handleValidation = () => {
        let errs = { ...validationError }
        Object.keys(errs).forEach(e => (errs[e] = ''))
        if (state.email_address === '') {
            errs.email_address = 'Podaj swój adres email'
        } else if (
            !state.email_address
                .toLowerCase()
                .match(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            errs.email_address = 'Podaj adres email w prawidłowym formacie'
        }
        if (state.password === '') {
            errs.password = 'Podaj hasło'
        } else if (state.password !== state.password_repeat) {
            errs.password = 'Hasła muszą być identyczne.'
        }

        if (state.album_number === '') {
            errs.album_number = 'Podaj swój numer albumu'
        }

        if (state.department_name.length === 0) {
            errs.album_number = 'Wybierz swój wydział/y'
        }
        if (state.accepted === false) {
            errs.accepted = 'Aby się zarejestrować, musisz wyrazić zgodę.'
        }
        setValidationError(errs)
        return errs
    }

    const handleSubmit = async e => {
        if (e) {
            e.preventDefault()
        }
        const err = handleValidation()
        if (Object.values(err).filter(e => e !== '').length === 0) {
            try {
                const result = await AuthApiProvider(Api()).register(state)
                setToastSeverity('success')
                setToastTitle('Sukces')
                setToastMessage(
                    'Zarejestowano pomyślnie! Aktywuj swoje konto linkiem przesłanym na podany adres email.'
                )
                setToastOpen(true)
                setTimeout(() => {
                    history.push('/')
                }, 1500)
            } catch (e) {
                setToastSeverity('error')
                setToastTitle('Błąd')
                if (e.response) {
                    if (e.response.status === 400) {
                        setToastMessage(
                            'Uzytkownik o tym emailu bądź numerze albumu juz istnieje.'
                        )
                    } else {
                        setToastMessage(
                            'Wystąpił nieoczekiwany bład po stronie serwera.'
                        )
                    }
                } else {
                    setToastMessage('Brak połączenia z serwerem.')
                }
                setToastOpen(true)
            }
        } else {
            setGreedyCheck(true)
        }
    }

    React.useEffect(() => {
        if (greedyCheck) {
            handleValidation()
        }
    }, [state, greedyCheck])

    return (
        <React.Fragment>
            <Heading>Rejestracja</Heading>
            <Container className={classes.alert}>
                <Collapse in={toastOpen}>
                    <Alert onClose={handleToastClose} severity={toastSeverity}>
                        <AlertTitle>{toastTitle}</AlertTitle>
                        {toastMessage}
                    </Alert>
                </Collapse>
            </Container>
            <Container>
                <form
                    id="register-form"
                    autoComplete="off"
                    className={classes.root}
                    onSubmit={handleSubmit}
                >
                    <RegisterContext.Provider
                        value={{
                            state,
                            errors: validationError,
                            handleRegisterChange,
                        }}
                    >
                        <Grid container spacing={3}>
                            <BasicInputs />
                        </Grid>
                        <Grid container spacing={3}>
                            <StudentDetails />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                style={{ marginTop: '60px' }}
                            ></Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    required
                                    fullWidth
                                    error={validationError.accepted !== ''}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    required
                                                    color="primary"
                                                    checked={state.accepted}
                                                    onChange={handleAccept}
                                                />
                                            }
                                            label={
                                                <span>
                                                    Wyrażam zgodę na
                                                    przetwarzanie powyższych
                                                    udostępnionych danych
                                                    osobowych: adres e-mail, nr
                                                    albumu, rok rozpoczęcia
                                                    studiów, wydział w celu
                                                    stworzenia bilansu
                                                    kompetencji oraz oświadczam,
                                                    że przeczytałem i akceptuję{' '}
                                                    <Link
                                                        href="https://ateneum.edu.pl/polityka-prywatnosci"
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        politykę prywatności
                                                    </Link>
                                                    .
                                                </span>
                                            }
                                        />
                                    </FormGroup>
                                    <FormHelperText>
                                        {validationError.accepted}
                                    </FormHelperText>
                                </FormControl>
                                <p>
                                    Administratorem danych osobowych jest
                                    Ateneum - Szkoła Wyższa z siedzibą w
                                    Gdańsku, 80-802 Gdańsk, ul. 3 Maja 25A
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction={'row-reverse'}>
                            <Grid item xs={12} md={5}>
                                <Button type="submit" onClick={handleSubmit}>
                                    Zarejestruj się
                                </Button>
                            </Grid>
                        </Grid>
                    </RegisterContext.Provider>
                </form>
            </Container>
        </React.Fragment>
    )
}
export default RegisterView
