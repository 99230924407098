import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Heading from 'components/typography/Heading'
import { GridContainer, Sidebar, Main, DividerContainer } from './components'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

import OptionsList from './OptionsList'
import { ChangePasswordOption } from './accountOptions'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
    },
    center: {
        margin: '0',
        [theme.breakpoints.up('md')]: {
            margin: 'auto',
        },
    },
    alert: {
        marginTop: 15,
    },
}))

function AccountView() {
    const classes = useStyles()
    const [options, setOptions] = React.useState([{ name: 'Zmień hasło' }])
    const [currentOptionId, setCurrentOptionId] = React.useState(0)

    function handleOptionChange(id) {
        setCurrentOptionId(id)
    }

    function CurrentOption({ id, ...props }) {
        if (currentOptionId === 0) {
            return <ChangePasswordOption {...props} />
        }
    }

    return (
        <>
            <Heading>Moje konto</Heading>
            <Container>
                <GridContainer>
                    <Sidebar>
                        <OptionsList
                            optionsList={options}
                            handleOption={handleOptionChange}
                            selectedId={currentOptionId}
                        />
                    </Sidebar>
                    <DividerContainer>
                        <Divider orientation="vertical" />
                    </DividerContainer>

                    <Main>
                        <CurrentOption id={currentOptionId} />
                    </Main>
                </GridContainer>
            </Container>
        </>
    )
}

export default AccountView
