import styled from 'styled-components'

const GridContainer = styled.div`
    display: grid;
    height: 100%;

    & > * {
        margin: auto;
    }
`
export default GridContainer
