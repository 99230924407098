import React, { useState } from 'react'
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom'
import Heading from 'components/typography/Heading'
import { Button } from 'components/buttons/Button'
import { Form, Heading2 } from './components'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Collapse from '@material-ui/core/Collapse'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useAuth } from 'context/auth'

import { Api } from 'services/api'
import { AuthApiProvider } from 'services/auth'
import { isAdminToken } from 'services/utils'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100px',
        minHeight: props => (props.normalScreen ? '400px' : '650px'),
        margin: '50px auto',
        width: '100%',
    },
    login: {
        marginTop: '50px',
    },
    alert: {
        marginTop: 15,
    },
}))

function LoginView() {
    const theme = useTheme()
    const normalScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles({ normalScreen })
    const history = useHistory()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [validationError, setValidationError] = useState({
        usernameErr: '',
        passwordErr: '',
    })
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    function handleValidation() {
        let errs = { ...validationError }
        if (username === '') {
            errs.usernameErr = 'Podaj adres email'
        } else if (
            !username
                .toLowerCase()
                .match(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            errs.usernameErr = 'Podaj adres email w prawidłowym formacie'
        } else {
            errs.usernameErr = ''
        }
        if (password === '') {
            errs.passwordErr = 'Podaj hasło'
        } else {
            errs.passwordErr = ''
        }
        setValidationError(errs)
        return errs
    }

    async function handleLogin(e) {
        e.preventDefault()
        const err = handleValidation()
        if (err.usernameErr === '' && err.passwordErr === '') {
            try {
                const res = await AuthApiProvider(ApiContext).login(
                    username,
                    password
                )
                setAuthTokens({
                    access_token: res.data.access_token,
                    refresh_token: res.data.access_token,
                })
                setToastSeverity('success')
                setToastTitle('Sukces')
                setToastMessage('Zalogowano pomyślnie!')
                setToastOpen(true)
            } catch (resErr) {
                setToastSeverity('error')
                if (resErr.response) {
                    let msg = ''
                    if (resErr.response.status === 401) {
                        setToastTitle('Spróbuj ponownie')
                        msg = 'Zły email lub hasło'
                    } else if (resErr.response.status === 403) {
                        setToastTitle('Konto nie jest aktywne')
                        msg = 'Skontaktuj się z administratorem.'
                    } else {
                        setToastTitle('Błąd')
                        msg = 'Wystąpił nieoczekiwany bład po stronie serwera.'
                    }

                    setToastMessage(msg)
                } else {
                    setToastMessage('Brak połączenia z serwerem.')
                }

                setToastOpen(true)
            }
        }
    }

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
        if (authTokens) {
            if (isAdminToken(authTokens)) {
                history.push('/admin')
            } else {
                history.push('/')
            }
        }
    }

    if (authTokens) {
        return <Redirect to="/" />
    }

    return (
        <React.Fragment>
            <Heading>Logowanie</Heading>
            <Container className={classes.alert}>
                <Collapse in={toastOpen}>
                    <Alert onClose={handleToastClose} severity={toastSeverity}>
                        <AlertTitle>{toastTitle}</AlertTitle>
                        {toastMessage}
                    </Alert>
                </Collapse>
            </Container>
            <Grid
                container
                spacing={2}
                justify="center"
                alignItems="stretch"
                className={classes.root}
            >
                <Grid container item xs={12} sm={5} md={4} spacing={2}>
                    <Container>
                        <Heading2 align={'right'}>Mam już konto</Heading2>
                        <Form>
                            <TextField
                                fullWidth
                                id="username"
                                type="text"
                                label="Email"
                                error={validationError.usernameErr !== ''}
                                helperText={validationError.usernameErr}
                                value={username}
                                onChange={e => {
                                    setUsername(e.target.value)
                                }}
                            />
                            <TextField
                                fullWidth
                                id="password"
                                type="password"
                                label="Hasło"
                                error={validationError.passwordErr !== ''}
                                helperText={validationError.passwordErr}
                                onChange={e => {
                                    setPassword(e.target.value)
                                }}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleLogin}
                            >
                                Zaloguj się
                            </Button>
                            <Link
                                underline="none"
                                component={RouterLink}
                                to={'/recover-password'}
                            >
                                Zapomniałeś hasła?
                            </Link>
                        </Form>
                    </Container>
                </Grid>
                <Divider
                    orientation={normalScreen ? 'vertical' : 'horizontal'}
                />
                <Grid container item xs={12} sm={5} md={4} spacing={2}>
                    <Container>
                        <Heading2 align={'left'}>
                            Jeszcze nie mam konta
                        </Heading2>
                        <RouterLink
                            to="/register"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button className={classes.login}>
                                Załóż konto
                            </Button>
                        </RouterLink>
                    </Container>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LoginView
