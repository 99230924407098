import React from 'react'
import NavLink, { LinkContainer } from 'components/navbar/NavLink'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ThemeMenu from 'components/navbar/ThemeMenu'

function AdminNav({ logout }) {
    const isMobile = useMediaQuery('(max-width:959px)')
    return (
        <React.Fragment>
            {isMobile ? (
                <React.Fragment>
                    <ListItem
                        button
                        component={Link}
                        key={'Administracja'}
                        to="/admin"
                        styles={{
                            borderRight: '4px solid #0c69c0',
                        }}
                    >
                        <ListItemText primary={'Administracja'} />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        key={'Widok studenta'}
                        to="/"
                        styles={{
                            borderRight: '4px solid #ffd800',
                        }}
                    >
                        <ListItemText primary={'Widok studenta'} />
                    </ListItem>
                    <ListItem button key={'Wyloguj'} onClick={logout}>
                        <ListItemText primary={'Wyloguj'} />
                    </ListItem>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <LinkContainer>
                        <NavLink to="/admin" borderColor={'#0c69c0'}>
                            Administracja
                        </NavLink>
                        <NavLink to="/" borderColor={'#ffd800'}>
                            Widok studenta
                        </NavLink>
                    </LinkContainer>
                    <div>
                        <ThemeMenu />
                        <Button onClick={logout}>Wyloguj</Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default AdminNav
