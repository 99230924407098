import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const useStyles = makeStyles(theme => ({
    card: {
        padding: '15px 30px',
        border: theme.palette.type === 'dark' ? '1px solid #fff' : 'none',
    },
    radio: {
        '&:hover': {
            border:
                theme.palette.type === 'dark' ? '1px solid #ffff00' : 'none',
            backgroundColor:
                theme.palette.type === 'dark' ? '#ffff00' : 'transparent',
        },
        '&:focus': {
            border:
                theme.palette.type === 'dark' ? '1px solid #ffff00' : 'none',
            backgroundColor:
                theme.palette.type === 'dark' ? '#ffff00' : 'transparent',
        },
    },

    questionTitle: {
        paddingBottom: '30px',
    },
}))

function QuestionComponent({ question, number, onAnswerClick }) {
    const classes = useStyles()
    const [value, setValue] = React.useState(false)

    const handleChange = event => {
        setValue(event.target.value)
        onAnswerClick({
            ques_id: number - 1,
            ans: [parseInt(event.target.value)],
        })
    }

    return (
        <Card className={classes.card} elevation={2}>
            <CardContent>
                <FormControl component="fieldset">
                    <FormLabel
                        component="legend"
                        className={classes.questionTitle}
                    >
                        <Typography variant="h3" component="h3">
                            {[number, '.', question.ques_text].join('')}
                        </Typography>
                    </FormLabel>

                    <RadioGroup
                        aria-label={number + '-odpowiedzi'}
                        name={number}
                        value={value}
                        onChange={handleChange}
                    >
                        {question.ans.map(el => (
                            <FormControlLabel
                                key={el.ans_id.toString() + '' + el.ans_text}
                                value={el.ans_id.toString()}
                                control={<Radio color="primary" />}
                                label={el.ans_text}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </CardContent>
        </Card>
    )
}

function questionPropsAreEqual(prevQues, nextQues) {
    return (
        prevQues.number === nextQues.number &&
        prevQues.ques_id === nextQues.ques_id &&
        prevQues.ques_text === nextQues.ques_text
    )
}

const MemoQuestionComponent = React.memo(
    QuestionComponent,
    questionPropsAreEqual
)
export default MemoQuestionComponent
