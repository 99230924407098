import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const defaultTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#00695c',
            main: '#009d69',
            dark: '#b2dfdb',
        },
        secondary: {
            main: '#e5f0ec',
            contrastText: '#b1cec4',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#ffffff',
        },
    },
    typography: {
        h1: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            fontSize: '3rem',
            '@media (min-width:600px)': {
                fontSize: '4.5rem',
            },
            color: '#b1cec4',
            display: 'block',
            maxWidth: '80%',
            margin: '0 auto',
            paddingTop: '20px',
        },
        h2: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            color: '#4c4c4c',
            fontSize: '2.2rem',
        },
        h3: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            color: '#4c4c4c',
            fontSize: '2rem',
        },
        a: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            color: '#006940',
        },
    },
    components: {
        radioButton: {
            checkedColor: '#558b2f',
            requiredColor: '#69f0ae',
        },
    },
})

const highContrast = createMuiTheme({
    palette: {
        type: 'dark',
        contrastThreshold: 3,
        tonalOffset: 0.2,
        primary: {
            main: '#ffff00',
            contrastText: '#000000',
            light: '#ffff00',
            dark: '#000000',
        },
        secondary: {
            main: '#000000',
            contrastText: '#ffffff',
        },
        error: {
            main: '#ff0000',
        },
        divider: '#ffffff',
        background: {
            paper: '#000000',
            default: '#000000',
        },
        text: {
            disabled: '#ffffff',
        },
    },
    typography: {
        h1: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            fontSize: '3rem',
            '@media (min-width:600px)': {
                fontSize: '4.5rem',
            },
            color: '#b1cec4',
            display: 'block',
            maxWidth: '80%',
            margin: '0 auto',
            paddingTop: '20px',
        },
        h2: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            color: '#ffffff',
            fontSize: '2.2rem',
        },
        h3: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            color: '#ffffff',
            fontSize: '2rem',
        },
        a: {
            fontFamily: ['"Bree Serif"', '"Times New Roman"', 'serif'].join(
                ','
            ),
            color: '#006940',
        },
    },
    components: {
        radioButton: {
            checkedColor: '#558b2f',
            requiredColor: '#69f0ae',
        },
    },
})

defaultTheme.typography.h1 = {
    [defaultTheme.breakpoints.up('md')]: {
        fontSize: '6rem',
    },
}

highContrast.typography.h1 = {
    [highContrast.breakpoints.up('md')]: {
        fontSize: '6rem',
    },
}

export { defaultTheme, highContrast }
