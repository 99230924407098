import * as React from 'react'

import { FooterContainer } from './styles'
import FooterLogo from 'components/logos/FooterLogo'

const Footer = props => (
    <FooterContainer>
        <FooterLogo />
    </FooterContainer>
)
export default Footer
