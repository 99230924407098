import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'

export const HeaderContainer = styled(AppBar)`
    font-family: 'Bree Serif', serif;
    font-weight: 'regular';
`
export const HeaderMain = styled.div`
    display: flex;
    flex-direction: row;
`

export const HeadTitle = styled.span`
    font-weight: 'bold';
    max-width: 200px;
    /**margin: auto 10px auto 23px; */
    font-size: 1.3rem;
    line-height: 1rem;

    @media (min-width: 768px) {
        font-size: 2.3rem;
        line-height: 2rem;
    }
`

export const Horizontal = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`

export const NavChildren = styled.nav`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-items: center;
    justify-content: space-between;
    align-items: stretch;
`

export const CookiePrompt = styled.div`
    display: flex;
    flex-direction: column;
    & > div {
        font-size: 1.1rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
    }
`
