import React, { useState } from 'react'
import PasswordInput from 'scenes/auth/components/PasswordInput'

import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'

import { useAuth } from 'context/auth'
import { Api, APIURL } from 'services/api'
import { AuthApiProvider } from 'services/auth'

import { Heading2 } from './components'

const useStyles = makeStyles(theme => ({
    login: {
        marginTop: '50px',
    },
    alert: {
        marginTop: 15,
    },
}))

const isStrongPassword = pass => {
    const re = new RegExp(
        /^((?=.*[0-9])(?=.*[a-z])(?=.*[\W\s\t\0])(?=.*[A-Z]))([^\n\t\r\s]{8,})$/gm
    )
    return re.test(pass)
}

function ChangePasswordInputs({ passwordChange }) {
    const [state, setState] = React.useState({
        old_password: '',
        new_password: '',
        new_password_repeat: '',
    })
    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.value })
        passwordChange({ ...state, [event.target.name]: event.target.value })
    }
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        if (state.new_password !== state.new_password_repeat) {
            setError('Hasła muszą być identyczne.')
        } else if (
            state.new_password !== '' &&
            !isStrongPassword(state.new_password)
        ) {
            setError(
                'Hasło musi zawierać conajmniej 8 znaków oraz składać się z conajmniej 1 małej litery, 1 wielkiej litery, 1 cyfry i 1 znaku specjalnego.'
            )
        } else {
            setError(false)
        }
    }, [state])

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="old_password"
                    type="password"
                    label="Stare hasło"
                    value={state.password}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="new_password"
                    type="password"
                    label="Nowe hasło"
                    error={error ? true : false}
                    helperText={error || 'Silne hasło.'}
                    value={state.password}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="new_password_repeat"
                    type="password"
                    label="Powtórz nowe hasło"
                    error={error ? true : false}
                    value={state.password_repeat}
                    onChange={handleChange}
                />
            </Grid>
        </React.Fragment>
    )
}

function ChangePasswordOption() {
    const classes = useStyles()
    const { authTokens, setAuthTokens } = useAuth()
    const [passwords, setPasswords] = useState({
        old_password: '',
        new_password: '',
        new_password_repeat: '',
    })
    const [validationError, setValidationError] = useState('')

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handlePasswordChange = passwords => {
        setPasswords(passwords)
    }

    function handleValidation() {
        let err = ''
        Object.values(passwords).forEach(v => {
            if (v === '') {
                err = 'Wypelnij wszystkie pola'
                console.log(passwords)
            }
        })
        setValidationError(err)
        return err
    }

    async function changePassword() {
        const err = handleValidation()
        if (err === '') {
            try {
                await AuthApiProvider(
                    Api(authTokens, setAuthTokens)
                ).updatePasswordStatic(passwords)
                setToastSeverity('success')
                setToastTitle('Sukces')
                setToastMessage('Pomyślnie zmieniono hasło')
                setToastOpen(true)
            } catch (e) {
                setToastSeverity('error')
                setToastMessage('Brak połączenia z serwerem.')
                setToastOpen(true)
            }
        } else {
            setToastSeverity('warning')
            setToastTitle('')
            setToastMessage(err)
            setToastOpen(true)
        }
    }

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    return (
        <React.Fragment>
            <Heading2>Zmień hasło</Heading2>
            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>
            <Grid container spacing={3}>
                <ChangePasswordInputs passwordChange={handlePasswordChange} />
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={changePassword}
                    >
                        Zmień hasło
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { ChangePasswordOption }
