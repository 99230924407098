import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
const isEqual = require('react-fast-compare')

const useStyles = makeStyles(theme => ({
    questionNo: {
        display: 'block',
        marginBottom: theme.spacing(1),
    },
}))

function PanelSummary({
    questions_length,
    count_of_questions_to_draw,
    handleStateChange,
    send,
    commitLabel,
}) {
    const classes = useStyles()
    return (
        <React.Fragment>
            <span className={classes.questionNo}>
                Pula pytań: {questions_length}
            </span>
            <TextField
                fullWidth
                label="Liczba pytań"
                type="number"
                id="count_of_questions_to_draw"
                name="count_of_questions_to_draw"
                onChange={handleStateChange}
                min={1}
                max={questions_length}
                className={classes.questionNo}
                helperText={`Ile pytań z puli ma być wylosowanych dla konkretnego testu (min: 1 max: ${questions_length}), domyslnie max`}
            />

            <Button variant="contained" color="primary" onClick={send}>
                {commitLabel}
            </Button>
        </React.Fragment>
    )
}

export default PanelSummary
