import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { Button } from 'components/buttons/Button'
import Heading from 'components/typography/Heading'
import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { QuizApiProvider } from 'services/quiz'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ConfirmModal from './ConfirmModal'
import QuestionComponent from './QuestionComponent'

const useStyles = makeStyles(theme => ({
    root: {
        height: props => (props.normalScreen ? '50vh' : '100%'),
        margin: '50px auto',
        width: '100%',
    },
    sideLinks: {
        display: 'flex',
        flexDirection: 'column',
    },
    progress: {
        position: 'sticky',
        top: 0,
        bottom: 20,
        zIndex: 5,
    },
    alert: {
        marginTop: 20,
    },
}))

const QuizProgress = withStyles(theme => ({
    colorPrimary: {
        backgroundColor: theme.palette.primary.dark,
    },
    barColorPrimary: {
        backgroundColor: theme.palette.primary.light,
    },
}))(LinearProgress)

function QuizView() {
    const classes = useStyles()
    const { testId } = useParams()
    const history = useHistory()
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)
    const [quiz, setQuiz] = React.useState({})
    async function getQuizFromAPI() {
        try {
            const res = await QuizApiProvider(ApiContext).getQuiz(testId)
            setQuiz(res.data.data)
        } catch (e) {
            setToastSeverity('error')
            if (e.response) {
                if (e.response.status === 403) {
                    setToastTitle('Konto nie jest aktywne')
                    setToastMessage('Skontaktuj się z administratorem.')
                } else {
                    setToastTitle('Błąd')
                    setToastMessage(
                        'Wystąpił nieoczekiwany bład po stronie serwera. Spróbuj ponownie później.'
                    )
                }
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }

            setToastOpen(true)
            setTimeout(() => {
                history.push('/')
            }, 1500)
        }
    }

    async function sendResponsesToAPI() {
        try {
            const data = {
                hash: quiz.hash,
                test_id: parseInt(testId),
                data: [...answers],
            }
            await QuizApiProvider(ApiContext).addQuizResponse(data)
            setToastSeverity('success')
            setToastTitle('Sukces')
            setToastMessage(
                'Odpowiedzi wysłane! Zostaniesz przeniesiony do strony głównej.'
            )
            setToastOpen(true)
            setTimeout(() => {
                history.push('/')
            }, 1500)
        } catch (e) {
            setToastSeverity('error')
            if (e.response) {
                if (e.response.status === 403) {
                    setToastTitle('Konto nie jest aktywne')
                    setToastMessage('Skontaktuj się z administratorem.')
                } else if (
                    e.response.message &&
                    e.response.message.detail ===
                        'user wypełnił ankietę już wcześniej'
                ) {
                    setToastSeverity('info')
                    setToastTitle('Błąd')
                    setToastMessage(
                        'Wypełniono juz ta ankietę. Zostaniesz przeniesiony do strony głównej.'
                    )
                } else {
                    setToastTitle('Błąd')
                    setToastMessage(
                        'Wystąpił nieoczekiwany bład po stronie serwera. Spróbuj ponownie później.'
                    )
                }
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
            setTimeout(() => {
                history.push('/')
            }, 1500)
        }
    }

    React.useEffect(() => {
        getQuizFromAPI()
    }, [])

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    const [answers, setAnswers] = React.useState([])
    const handleChange = ({ ques_id, ans }) => {
        setAnswers(oldAnswers => [
            ...oldAnswers.filter(e => e.ques_id !== ques_id),
            {
                ques_id: ques_id,
                ans: ans,
            },
        ])
    }

    function checkValidAns() {
        if (quiz.data.length > answers.length) {
            return false
        }
        return true
    }

    function shouldDisableSend() {
        if (quiz.data) {
            return answers.length !== quiz.data.length ? true : false
        } else {
            return false
        }
    }

    const [modalOpen, setModalOpen] = React.useState(false)
    const [confirmSubmit, setConfirm] = React.useState(false)
    React.useEffect(() => {
        if (confirmSubmit === true) {
            sendResponsesToAPI()
        }
    }, [confirmSubmit])
    const [openAlert, setOpenAlert] = React.useState(false)
    const [messageType, setMessageType] = React.useState('info')

    return (
        <>
            <ConfirmModal
                open={modalOpen}
                setOpen={setModalOpen}
                setConfirm={setConfirm}
            />
            <Heading>{quiz.tittle}</Heading>
            <div className={classes.progress}>
                {quiz.data && (
                    <QuizProgress
                        variant="determinate"
                        value={(answers.length * 100) / quiz.data.length}
                    />
                )}
            </div>
            <Container className={classes.alert}>
                <Collapse in={toastOpen}>
                    <Alert onClose={handleToastClose} severity={toastSeverity}>
                        <AlertTitle>{toastTitle}</AlertTitle>
                        {toastMessage}
                    </Alert>
                </Collapse>
            </Container>
            <form
                onSubmit={e => {
                    e.preventDefault()
                    if (checkValidAns() === true) {
                        setModalOpen(true)
                    }
                }}
            >
                <Container>
                    <Grid
                        container
                        spacing={7}
                        justify="center"
                        alignItems="stretch"
                        className={classes.root}
                    >
                        {quiz.data &&
                            quiz.data.map((el, i) => (
                                <Grid item xs={12} key={'question-' + i}>
                                    <QuestionComponent
                                        question={el}
                                        number={i + 1}
                                        onAnswerClick={handleChange}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    <Grid container justify="center" alignItems="stretch">
                        <Grid item xs={12}>
                            <Collapse in={openAlert}>
                                <Alert
                                    severity={messageType}
                                    open={openAlert}
                                    onClose={() => {
                                        setOpenAlert(false)
                                    }}
                                >
                                    {messageType === 'info'
                                        ? 'Musisz uzupełnić wszystkie odpowiedzi.'
                                        : 'Błąd po stronie serwera. Spróbuj ponownie później.'}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" component="span">
                                {quiz.data
                                    ? answers.length !== quiz.data.length
                                        ? `Odpowiedziano na ${(
                                              (answers.length * 100) /
                                              quiz.data.length
                                          ).toFixed(2)}% testu`
                                        : 'Odpowiedziano na wszystkie pytania'
                                    : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                disabled={shouldDisableSend()}
                                type="button"
                                onClick={() => {
                                    if (checkValidAns() === true) {
                                        setModalOpen(true)
                                    }
                                }}
                            >
                                Zakończ test
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )
}
export default QuizView
