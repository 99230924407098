import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
const isEqual = require('react-fast-compare')

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: `0 ${theme.spacing(1)}`,
        minWidth: 320,
    },
}))

function PanelOptions({ correlated_quiz_id, correlated, setCorrelatedId }) {
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="correlated_quiz_id-label">
                Skorelowany test (opcjonalnie)
            </InputLabel>
            <Select
                native
                labelId="correlated_quiz_id-label"
                value={correlated_quiz_id || ''}
                onChange={e => {
                    setCorrelatedId(parseInt(e.target.value))
                }}
                inputProps={{
                    name: 'correlated_quiz_id',
                    id: 'correlated_quiz_id',
                }}
            >
                <option aria-label="None" value="" />
                {correlated.map(c => (
                    <option value={c.id} key={`corr-${c.id}`}>
                        {c.text}
                    </option>
                ))}
            </Select>
        </FormControl>
    )
}

function arePropsEqual(p, n) {
    return (
        isEqual(p.correlated_quiz_id, n.correlated_quiz_id) &&
        isEqual(p.correlated, n.correlated)
    )
}
export default React.memo(PanelOptions, arePropsEqual)
