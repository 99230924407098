import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Heading from 'components/typography/Heading'
import { useAuth } from 'context/auth'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {
    AddTest,
    AllTests,
    EditTest,
    TestsResults,
    StudentsList,
    CreateRapport,
} from 'scenes/admin/index'
import {
    DividerContainer,
    GridContainer,
    Main,
    Sidebar,
} from 'scenes/home/components'
import OptionsList from './OptionsList'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
    },
    center: {
        margin: '0',
        [theme.breakpoints.up('md')]: {
            margin: 'auto',
        },
    },
    alert: {
        marginTop: 15,
    },
    container: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
}))

const options = [
    {
        link: '/admin/students',
        text: 'Zarejestrowani Studenci',
    },
    {
        link: '/admin/tests/results',
        text: 'Wyniki testów',
    },
    {
        link: '/admin/tests/all',
        text: 'Wszystkie testy',
    },
    {
        link: '/admin/tests/new',
        text: 'Stwórz nowy test',
    },
    /**
    *  {
        link: '/admin/tests/rapports',
        text: 'Stwórz raport',
    },
    */
]

function AdminView() {
    const classes = useStyles()
    const { authTokens } = useAuth()
    const [tests, setTests] = React.useState([])
    const [currentTestId, setCurrentTestId] = React.useState('')

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    return (
        <>
            <Heading>Zarządzanie testami</Heading>
            <Container className={classes.container}>
                <Router>
                    <GridContainer>
                        <Sidebar>
                            <OptionsList options={options} />
                        </Sidebar>
                        <DividerContainer>
                            <Divider orientation="vertical" />
                        </DividerContainer>

                        <Main>
                            <Switch>
                                <Route
                                    exact
                                    path="/admin/tests/all"
                                    component={AllTests}
                                />
                                <Route
                                    exact
                                    path="/admin/tests/new"
                                    component={AddTest}
                                />
                                <Route
                                    exact
                                    path="/admin/tests/results"
                                    component={TestsResults}
                                />
                                <Route
                                    exact
                                    path="/admin/tests/:testId/edit"
                                    component={EditTest}
                                />
                                {/*<Route
                                    exact
                                    path="/admin/tests/rapports"
                                    component={CreateRapport}
                                /> */}
                                <Route
                                    exact
                                    path="/admin/students"
                                    component={StudentsList}
                                />
                            </Switch>

                            <Container className={classes.alert}>
                                <Collapse in={toastOpen}>
                                    <Alert
                                        onClose={handleToastClose}
                                        severity={toastSeverity}
                                    >
                                        <AlertTitle>{toastTitle}</AlertTitle>
                                        {toastMessage}
                                    </Alert>
                                </Collapse>
                            </Container>
                        </Main>
                    </GridContainer>
                </Router>
            </Container>
        </>
    )
}

export default AdminView
