import axios from 'axios'

export const APIURL = process.env.REACT_APP_APIURL

export const Api = (authTokens, setAuthTokens) => {
    const Api = axios.create({
        baseURL: process.env.REACT_APP_APIURL,
        responseType: 'json',
    })
    Api.interceptors.request.use(
        config => {
            if (authTokens) {
                config.headers['Authorization'] =
                    'Bearer ' + authTokens.access_token
            }
            config.headers['Content-Type'] =
                config.url === '/tokens'
                    ? 'application/x-www-form-urlencoded'
                    : 'application/json'
            return config
        },
        error => {
            Promise.reject(error)
        }
    )

    Api.interceptors.response.use(
        response => {
            return response
        },
        function(error) {
            const originalRequest = error.config
            if (authTokens && error.response) {
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true
                    return Api.post('/tokens/refresh', {
                        token: authTokens.refresh_token,
                    }).then(res => {
                        if (res.status === 201) {
                            setAuthTokens(res.data)
                            axios.defaults.headers.common['Authorization'] =
                                'Bearer ' + res.data.access_token
                            return Api(originalRequest)
                        }
                    })
                } else if (error.response.status === 401) {
                    setAuthTokens(null)
                } else {
                    throw error
                }
            }
            throw error
        }
    )

    return Api
}
