import MButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

export const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.24), 0 3px 6px 0 rgba(229, 240, 236, 0.16)`,
        borderRadius: '4px',
        fontFamily: ['Raleway', 'Arial', 'sans-serif'].join(','),
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
        margin: '15px 0',
        padding: '1rem',
        border: 'none',
        width: '100%',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor:
                theme.palette.type === 'dark'
                    ? theme.palette.primary.main
                    : '#03c182',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '&:disabled': {
            border:
                theme.palette.type === 'dark'
                    ? `1px solid ${theme.palette.text.disabled}`
                    : 'none',
            color: theme.palette.text.disabled,
            backgroundColor:
                theme.palette.type === 'dark'
                    ? '#000000 !important'
                    : '#dfdfdf !important',
        },
    },
}))(MButton)
