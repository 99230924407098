import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Heading from 'components/typography/Heading'
import Typography from '@material-ui/core/Typography'
import {
    Heading2,
    GridContainer,
    Sidebar,
    Main,
    DividerContainer,
} from './components'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Collapse from '@material-ui/core/Collapse'
import { Button } from 'components/buttons/Button'

import { useAuth } from 'context/auth'
import { Api, APIURL } from 'services/api'
import { QuizApiProvider } from 'services/quiz'

import QuizList from './QuizList'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
    },
    center: {
        margin: '0',
        [theme.breakpoints.up('md')]: {
            margin: 'auto 0',
        },
    },

    alert: {
        marginTop: 15,
    },
    container: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
}))

function HomeView() {
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)
    const [tests, setTests] = React.useState([])
    const [currentTestId, setCurrentTestId] = React.useState('')

    function handleTestChange(id) {
        setCurrentTestId(id)
    }

    async function getTests() {
        try {
            const res = await QuizApiProvider(ApiContext).getAvailableQuizzes()
            setTests(res.data.data)
        } catch (e) {
            setToastSeverity('error')
            if (e.response) {
                if (e.response.status === 403) {
                    setToastTitle('Konto nie jest aktywne')
                    setToastMessage('Skontaktuj się z administratorem.')
                } else {
                    setToastTitle('Błąd')
                    setToastMessage(
                        'Wystąpił nieoczekiwany bład po stronie serwera.'
                    )
                }
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }

            setToastOpen(true)
        }
    }

    React.useEffect(() => {
        getTests()
    }, [])

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    const chooseQuiz = currentTest => {
        /**
         currentTest.messages = [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. (Prog 1)',
            'Ut pharetra odio a aliquam bibendum. (Prog 2 itd)',
            'Aliquam a pretium sem, sed porta est. Nullam sit amet urna at est aliquam tempor.  ',
            'Donec quis leo quam. Aliquam ligula lorem, ultricies id faucibus eget, bibendum id metus. ',
        ]
         */
        return (
            <React.Fragment>
                <Heading2>{currentTest.quiz_name}</Heading2>
                {currentTest.desc && currentTest.desc.length > 0 && (
                    <Typography>{currentTest.desc}</Typography>
                )}

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    spacing={1}
                >
                    <Grid item xs={12} md={5} className={classes.grid}>
                        {typeof currentTest.points_percentage === 'number' ? (
                            <>
                                <Typography
                                    variant="h3"
                                    component="span"
                                    className={classes.center}
                                >
                                    Uzyskano {currentTest.points_sum}pkt /{' '}
                                    {currentTest.max_point_sum}
                                </Typography>
                            </>
                        ) : (
                            <Link
                                to={`/test/${currentTestId}`}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button fullWidth>Wypełnij test</Button>
                            </Link>
                        )}
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <a
                            href={APIURL + currentTest.link_to_rapport}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                fullWidth
                                disabled={
                                    currentTest.link_to_rapport ? false : true
                                }
                            >
                                Pobierz raport w pdf
                            </Button>
                        </a>
                    </Grid>

                    {currentTest.message && (
                        <Grid item xs={12} className={classes.messages}>
                            <p>{currentTest.message}</p>
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <>
            <Heading>Moje testy</Heading>
            <Container className={classes.container}>
                <GridContainer>
                    <Sidebar>
                        <QuizList
                            testList={tests}
                            handleTest={handleTestChange}
                            selectedId={currentTestId}
                        />
                    </Sidebar>
                    <DividerContainer>
                        <Divider orientation="vertical" />
                    </DividerContainer>

                    <Main>
                        {currentTestId !== '' ? (
                            chooseQuiz(
                                tests.filter(t => t.id === currentTestId)[0]
                            )
                        ) : (
                            <Heading2>
                                {tests.length === 0
                                    ? 'Brak dostępnych testów'
                                    : 'Proszę wybrać test z listy'}
                            </Heading2>
                        )}
                        <Container className={classes.alert}>
                            <Collapse in={toastOpen}>
                                <Alert
                                    onClose={handleToastClose}
                                    severity={toastSeverity}
                                >
                                    <AlertTitle>{toastTitle}</AlertTitle>
                                    {toastMessage}
                                </Alert>
                            </Collapse>
                        </Container>
                    </Main>
                </GridContainer>
            </Container>
        </>
    )
}

export default HomeView
