const countMaxSum = questions => {
    const d = questions
        .map(q => q.question_entities.map(a => a.question_value))
        .map(a => Math.max(...a))
        .reduce((p, c) => p + c)
    if (Number.isNaN(d)) {
        return null
    }
    return d
}

const addIdstoQuestions = questions =>
    questions.map(q => ({
        question_tittle: q.question_tittle,
        question_entities: q.question_entities.map((e, i) => {
            return {
                question_id: i,
                question_header: e.question_header,
                question_value: e.question_value,
            }
        }),
    }))

export const transformDataToSend = state => {
    const payload = {
        ...state,
        questions_to_draw: addIdstoQuestions(state.questions_to_draw),
        count_of_questions_to_draw:
            state.count_of_questions_to_draw === null
                ? state.questions_to_draw.length
                : parseInt(state.count_of_questions_to_draw),
        final_date: state.final_date.toISOString(),
        correlated_quiz_id: Number.isNaN(parseInt(state.correlated_quiz_id))
            ? null
            : parseInt(state.correlated_quiz_id),
        max_point_sum: countMaxSum(state.questions_to_draw),
    }
    return payload
}

export const transformDataToState = res => {
    let state = {
        ...res,
        available_for_group: res.available_for_group.map(n => parseInt(n)),
        final_date: new Date(res.final_date),
    }
    return state
}
