import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Heading from 'components/typography/Heading'
import React from 'react'

function CookiesView() {
    return (
        <>
            <Heading>Ciasteczka na stronie</Heading>
            <Container>
                <p>
                    W celu lepszego dostosowywania treści i struktury serwisu do
                    Państwa potrzeb używamy mechanizmu cookies. Informacje
                    gromadzone za pomocą cookies to m.in.: adres IP, dane
                    dotyczące przeglądarki oraz systemu operacyjnego. Informacje
                    te są przechowywane z zachowaniem takich samych standardów,
                    jak dane osobowe. Mając dostęp do Internetu mogą Państwo
                    skorzystać z serwisów pozwalających na wyłączenie cookies.
                    Tak zbierane informacje będą wykorzystywane w sposób nie
                    naruszający prawa do prywatności. Funkcjonowanie cookies
                    pozwala na lepsze dostosowanie serwisu do Państwa wymagań.{' '}
                </p>

                <Link to="/">
                    <Button>Powrót do strony głównej</Button>
                </Link>
            </Container>
        </>
    )
}
export default CookiesView
