import React from 'react'
import update from 'immutability-helper'
/* material */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Collapse from '@material-ui/core/Collapse'

/* custom */
import DepartmentsCheckbox from './components/DepartmentCheckbox'
import { Heading2 } from 'scenes/home/components'
import QuestionsList from './components/QuestionsList'
import AddQuestionButton from './components/AddQuestionButton'
import PanelDescription from './PanelDescription'
import PanelOptions from './PanelOptions'
import PanelSummary from './PanelSummary'
/* api */
import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { AdminApiProvider } from 'services/admin'
import { transformDataToSend } from './utils/transformDataToSend'

const useStyles = makeStyles(theme => ({
    alert: {
        marginBottom: theme.spacing(2),
    },
    addQuestion: {
        marginTop: theme.spacing(2),
    },
    panelContainer: {
        marginBottom: theme.spacing(2),
    },
}))

function AddTest() {
    const classes = useStyles()
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)
    const [quiz_name, setQuizName] = React.useState('')
    const [final_date, setFinalDate] = React.useState(new Date())
    const [correlated, setCorrelated] = React.useState([])
    const [correlated_quiz_id, setCorrelatedId] = React.useState(null)

    const [available_for_group, setGroups] = React.useState([])

    const [messages, setMessages] = React.useState([
        {
            min_points: 0,
            message: '',
        },
    ])
    const [questions_to_draw, setQuestions] = React.useState([
        {
            question_tittle: '',
            question_entities: [
                { question_id: 0, question_header: '', question_value: 0 },
                { question_id: 1, question_header: '', question_value: 0 },
            ],
        },
    ])
    const [state, setState] = React.useState({
        count_of_questions_to_draw: 1,
    })

    async function populateCorrelated() {
        try {
            const res = await AdminApiProvider(ApiContext).getTestHeaders()
            setCorrelated(
                res.data.map(e => ({
                    id: e.quiz_id,
                    text: e.quiz_header,
                }))
            )
        } catch (e) {
            setToastSeverity('error')
            setToastMessage('Wystąpił błąd.')
            setToastOpen(true)
        }
    }

    async function sendEditedTest() {
        try {
            await AdminApiProvider(ApiContext).createNewTest(
                transformDataToSend({
                    ...state,
                    questions_to_draw,
                    quiz_name,
                    final_date,
                    correlated_quiz_id,
                    messages,
                    available_for_group,
                })
            )
            setToastSeverity('success')
            setToastTitle('Sukces')
            setToastMessage('Test utworzono pomyślnie!')
            setToastOpen(true)
        } catch (e) {
            setToastSeverity('error')
            setToastMessage('Wystąpił błąd.')
            setToastOpen(true)
        }
    }

    React.useEffect(() => {
        console.log({ messages })
    }, [messages])

    React.useEffect(() => {
        populateCorrelated()
    }, [])

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    const handleMessagesChange = i => e => {
        const currentMessage = messages[i]
        const name = e.target.name
        const newMessages = update(messages, {
            [i]: {
                $set: {
                    ...currentMessage,
                    [name]:
                        name === 'min_points'
                            ? parseInt(e.target.value)
                            : e.target.value,
                },
            },
        })
        setMessages(newMessages)
    }

    const handleAddMessage = () => {
        setMessages(
            update(messages, {
                $push: [
                    {
                        min_points: 0,
                        message: '',
                    },
                ],
            })
        )
    }

    const handleDeleteMessage = i => () => {
        setMessages(update(messages, { $splice: [[i, 1]] }))
    }

    const handleDepChange = dep => {
        setGroups(dep)
    }

    const addNewQuestion = () => {
        const q = update(questions_to_draw, {
            $push: [
                {
                    question_tittle: '',
                    question_entities: [
                        {
                            question_id: 0,
                            question_header: '',
                            question_value: 0,
                        },
                    ],
                },
            ],
        })
        setQuestions(q)
    }

    const handleQuestion = i => question => {
        const res = update(questions_to_draw, {
            [i]: { $set: question },
        })
        setQuestions(res)
    }

    const deleteQuestion = i => e => {
        setQuestions(update(questions_to_draw, { $splice: [[i, 1]] }))
    }

    const handleStateChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    return (
        <React.Fragment>
            <Heading2>Stwórz nowy test</Heading2>
            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>

            <Grid
                container
                justify="space-between"
                spacing={3}
                className={classes.panelContainer}
            >
                <PanelDescription
                    quiz_name={quiz_name}
                    setQuizName={setQuizName}
                    messages={messages}
                    handleMessagesChange={handleMessagesChange}
                    addMessage={handleAddMessage}
                    deleteMessage={handleDeleteMessage}
                    final_date={final_date}
                    setFinalDate={setFinalDate}
                />
                <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    justify="space-between"
                    alignItems="center"
                >
                    <PanelOptions
                        correlated_quiz_id={correlated_quiz_id}
                        correlated={correlated}
                        setCorrelatedId={setCorrelatedId}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DepartmentsCheckbox
                        value={available_for_group}
                        handleDepChange={handleDepChange}
                    />
                </Grid>
                <Grid item container direction="column" xs={12} sm={6}>
                    <PanelSummary
                        questions_length={questions_to_draw.length}
                        count_of_questions_to_draw={
                            state.count_of_questions_to_draw
                        }
                        send={sendEditedTest}
                        handleStateChange={handleStateChange}
                        commitLabel={'Stwórz test'}
                    />
                </Grid>
            </Grid>

            <QuestionsList
                questions_to_draw={questions_to_draw}
                deleteQuestion={deleteQuestion}
                handleQuestion={handleQuestion}
            />

            <AddQuestionButton
                className={classes.addQuestion}
                onClick={addNewQuestion}
            />
        </React.Fragment>
    )
}

export default AddTest
