import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Heading from 'components/typography/Heading'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Api } from 'services/api'
import { AuthApiProvider } from 'services/auth'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        margin: '50px auto',
        width: props => (props.normalScreen ? '50vw' : '100%'),
    },
    circleLoad: {
        width: '100%',
        display: 'flex',
        '& > *': {
            margin: 'auto',
        },
    },
    alert: {
        marginTop: 15,
    },
}))

function EmailConfirmView() {
    const theme = useTheme()
    const normalScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles({ normalScreen })
    const history = useHistory()
    const query = useQuery()
    const [loading, setLoading] = React.useState(true)
    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')
    const ApiContext = Api()
    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    async function handleActivation() {
        const email = query.get('email')
        const album_number = parseInt(query.get('album_number'))
        const userHash = query.get('hash')
        try {
            await AuthApiProvider(ApiContext).confirmEmail(
                email,
                album_number,
                userHash
            )
            setToastSeverity('success')
            setToastTitle('Sukces')
            setToastMessage(
                'Pomyślnie aktywowano konto! Przenoszenie do strony głównej ...'
            )
            setToastOpen(true)
            setLoading(false)
            setTimeout(() => {
                history.push('/')
            }, 3000)
        } catch (e) {
            setToastSeverity('error')
            setToastMessage(
                'Brak połączenia z serwerem. Przenoszenie do strony głównej.'
            )
            if (e.response) {
                if (e.response.status === 422) {
                    setToastMessage(
                        'Link niepoprawny bądź wygasł. Przenoszenie do strony głównej.'
                    )
                }
            }
            setToastOpen(true)
            setLoading(false)
            setTimeout(() => {
                history.push('/')
            }, 3000)
        }
    }

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    React.useEffect(() => {
        handleActivation()
    }, [])

    return (
        <React.Fragment>
            <Heading>Aktywacja konta</Heading>
            <Container className={classes.alert}>
                <Collapse in={toastOpen}>
                    <Alert onClose={handleToastClose} severity={toastSeverity}>
                        <AlertTitle>{toastTitle}</AlertTitle>
                        {toastMessage}
                    </Alert>
                </Collapse>
            </Container>
            <Container>
                <Grid
                    container
                    spacing={2}
                    justify="center"
                    alignItems="stretch"
                    className={classes.root}
                >
                    <Grid container item xs={12} spacing={2}>
                        <div className={classes.circleLoad}>
                            {loading && <CircularProgress />}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default EmailConfirmView
