import Home from './AdminView'
import AllTests from './AllTests'
import AddTest from './AddTest'
import EditTest from './EditTest'
import TestsResults from './TestsResults'
import StudentsList from './StudentsList'
import CreateRapport from './CreateRapport'

export {
    AllTests,
    AddTest,
    EditTest,
    TestsResults,
    StudentsList,
    CreateRapport,
}
export default Home
