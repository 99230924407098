const endpoints = {
    testResults: '/quizzes/admin/results/all',
    availableTestsList: '/quizzes/admin/all',
    testById: id => `/quizzes/admin/${id}`,
    testResultById: id => `/quizzes/result/${id}`,
    updateTest: id => `/quizzes/admin/update/${id}`,
    updateResultDate: resId => `/quizzes/admin/results/${resId}`,
    regenerateReport: `/rapports/data/regenerate`,
    test_is_active: id => `/quizzes/admin/${id}/is_active`,
    quiz_reminder: id => `/quizzes/admin/send_reminder/${id}`,
    newTest: '/quizzes/new',
    headers: '/quizzes/admin/all/headers/',
    studentsList: '/users/admin/all',
    user_admin_delete: id => `/users/admin/${id}`,
    user_list_activate: '/users/admin/activate/',
    user_list_deactivate: '/users/admin/deactivate/',
    user_is_active: id => `/users/admin/is_active/${id}`,
    user_is_email_active: id => `/users/admin/is_email_active/${id}`,
}

export const AdminApiProvider = Api => {
    const getAvailableTestsList = async () =>
        await Api.get(endpoints.availableTestsList, {
            params: {
                limit: -1,
            },
        })

    const getTestResults = async (
        skip,
        limit,
        searchAlbum,
        searchTestId,
        startDate,
        endDate
    ) => {
        let params = {
            skip,
            limit,
        }
        if (searchAlbum !== '') {
            params.album_number = searchAlbum
        }
        if (searchTestId !== '') {
            params.test_id = searchTestId
        }
        if (startDate !== '') {
            params.first_date = startDate.toISOString()
        }
        if (endDate !== '') {
            params.second_date = endDate.toISOString()
        }
        return await Api.get(endpoints.testResults, {
            params,
        })
    }

    const getStudentsList = async (
        skip,
        limit,
        searchAlbum,
        searchEmail,
        startDate,
        endDate
    ) => {
        let params = {
            skip,
            limit,
        }
        if (searchAlbum !== '') {
            params.album_number = searchAlbum
        }
        if (searchEmail !== '') {
            params.email = searchEmail
        }
        if (startDate !== '') {
            params.first_date = startDate.toISOString()
        }
        if (endDate !== '') {
            params.second_date = endDate.toISOString()
        }

        return await Api.get(endpoints.studentsList, {
            params,
        })
    }

    const getTestHeaders = async () => await Api.get(endpoints.headers)

    const createNewTest = async payload =>
        await Api.post(endpoints.newTest, payload)

    const getExistingTest = async id => await Api.get(endpoints.testById(id))

    const updateExistingTest = async (id, payload) =>
        await Api.put(endpoints.updateTest(id), payload)

    const updateResultDate = async (resId, payload) =>
        await Api.put(endpoints.updateResultDate(resId), payload)

    const regenerateReport = async (quizId, userId) =>
        await Api.put(endpoints.regenerateReport, {
            quiz_id: quizId,
            user_id: userId,
        })

    const switchIsActive = async id =>
        await Api.put(endpoints.test_is_active(id), {})

    const sendQuizReminder = async id =>
        await Api.post(endpoints.quiz_reminder(id), {})

    const switchUserIsActive = async id =>
        await Api.put(endpoints.user_is_active(id), {})

    const user_admin_delete = async id =>
        await Api.delete(endpoints.user_admin_delete(id), {})

    const users_admin_activate = async user_ids_array => {
        return await Api.put(endpoints.user_list_activate, {
            data: {
                list_of_id_admin: [...user_ids_array],
                list_of_id_email: [],
            },
        })
    }

    const users_admin_deactivate = async user_ids_array =>
        await Api.put(endpoints.user_list_deactivate, {
            data: {
                list_of_id_admin: [...user_ids_array],
                list_of_id_email: [],
            },
        })

    return {
        getAvailableTestsList,
        getTestResults,
        updateResultDate,
        getTestHeaders,
        createNewTest,
        getExistingTest,
        updateExistingTest,
        regenerateReport,
        switchIsActive,
        sendQuizReminder,
        getStudentsList,
        switchUserIsActive,
        user_admin_delete,
        users_admin_activate,
        users_admin_deactivate,
    }
}
