import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MaterialLink from '@material-ui/core/Link'
import { Heading2 } from 'scenes/home/components'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Pagination from '@material-ui/lab/Pagination'
import Skeleton from '@material-ui/lab/Skeleton'

import SearchFields from 'scenes/admin/components/SearchFields'

import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as moment from 'moment'
import 'moment/locale/pl'

import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { AdminApiProvider } from 'services/admin'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            width: 'max-content',
            margin: `${theme.spacing(2)}px auto`,
        },
    },
    table: {
        minHeight: 100,
    },
    skeletonContainer: {
        height: 640,
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    alert: {
        margin: `${theme.spacing(2)}px auto`,
    },
    searchButton: { backgroundColor: '#2376b7' },
    searchBar: { marginBottom: theme.spacing(2) },
}))

const headCells = [
    {
        id: 'quiz_id',
        label: 'ID testu',
        numeric: true,
        disablePadding: true,
        sortable: true,
    },
    {
        id: 'quiz_name',
        label: 'Tytuł',
        numeric: false,
        sortable: true,
    },
    {
        id: 'fill_time',
        label: 'Data napisania testu',
        numeric: false,
        disablePadding: false,
        sortable: true,
    },
    {
        id: 'user_album_number',
        label: 'Nr albumu',
        numeric: true,
        disablePadding: true,
        sortable: true,
    },
    {
        id: 'quiz_result_points',
        label: 'Wynik (pkt)',
        numeric: true,
        disablePadding: false,
        sortable: true,
    },
    {
        id: 'rapport_link',
        label: ' Link do raportu',
        numeric: true,
        disablePadding: false,
        sortable: false,
    },
    {
        id: 'regenerate_rapport',
        label: ' Zaktualizuj raport',
        numeric: false,
        disablePadding: false,
        sortable: false,
    },
]

function applySearch(row, search) {
    let on = false
    const fields = ['user_album_number', 'quiz_id']
    const reg = new RegExp(search, 'gi')
    for (const k of fields) {
        if (row[k].toString().match(reg)) {
            on = true
            break
        }
    }
    return on
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

function TestsResults(props) {
    const classes = useStyles()
    const [quizData, setQuizData] = useState(null)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(4)
    const [limit] = useState(50)
    const [selectOptions, setSelectOptions] = useState([{ id: 0, text: '' }])
    const [searchAlbum, setSearchAlbum] = useState('')
    const [searchTestId, setSearchTestID] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('fill_time')
    const [count, setCount] = React.useState(1)
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)
    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const handleSearch = e => {
        if (e.target.name === 'searchTestId') {
            setSearchTestID(e.target.value)
        } else if (e.target.name === 'searchAlbum') {
            setSearchAlbum(e.target.value)
        } else if (e.target.name === 'startDate') {
            setStartDate(e.target.value)
        } else if (e.target.name === 'endDate') {
            setEndDate(e.target.value)
        }
    }

    const resetField = e => {
        if (e.target.name === 'searchTestId') {
            setSearchTestID('')
        } else if (e.target.name === 'searchAlbum') {
            setSearchAlbum('')
        } else if (e.target.name === 'startDate') {
            setStartDate('')
        } else if (e.target.name === 'endDate') {
            setEndDate('')
        }
    }
    const handleResetAll = () => {
        setSearchTestID('')
        setSearchAlbum('')
        setStartDate('')
        setEndDate('')
        updateResults()
    }

    const updateResults = async () => {
        try {
            const skip = limit * (page - 1)
            const res = await AdminApiProvider(ApiContext).getTestResults(
                skip,
                limit,
                searchAlbum,
                searchTestId,
                startDate,
                endDate
            )
            const state = res.data.data.map(row => ({
                ...row,
                fill_time: row.fill_time.slice(0, 19),
            }))

            setMaxPage(parseInt((res.data.count + limit - 1) / limit))
            setQuizData(state)
        } catch (e) {
            console.log(e)
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    async function refreshSelectOptions() {
        try {
            const res = await AdminApiProvider(ApiContext).getTestHeaders()
            setSelectOptions(
                res.data.map(e => ({
                    id: e.quiz_id,
                    text: e.quiz_header,
                }))
            )
        } catch (e) {
            setToastSeverity('error')
            setToastMessage('Wystąpił błąd.')
            setToastOpen(true)
        }
    }

    const handleDateChange = quiz_result_id => date => {
        try {
            let quiz = null
            const s = quizData.slice().map(el => {
                if (el.quiz_result_id === quiz_result_id) {
                    quiz = {
                        ...el,
                        fill_time: date, //d.toISOString()
                    }
                    return quiz
                } else {
                    return el
                }
            })
            if (quiz) {
                AdminApiProvider(
                    ApiContext
                ).updateResultDate(quiz.quiz_result_id, { fill_time: date })
                setQuizData(s)
            }
        } catch (e) {
            setToastSeverity('error')
            updateResults()
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    const generateNewReport = async (quizId, userId) => {
        try {
            const res = await AdminApiProvider(ApiContext).regenerateReport(
                quizId,
                userId
            )
            console.log(res.data)
            setToastSeverity('success')
            setToastTitle('Sukces')
            setToastMessage('Pomyślnie wygenerowano nowy raport dla studenta')
            setToastOpen(true)
        } catch (e) {
            setToastSeverity('error')
            updateResults()
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const createSortHandler = property => event => {
        handleRequestSort(event, property)
    }

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    React.useEffect(() => {
        updateResults()
        refreshSelectOptions()
    }, [page])

    return (
        <React.Fragment>
            <Heading2>Wyniki testów</Heading2>

            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>
            {quizData ? (
                <React.Fragment>
                    <SearchFields
                        fields={[
                            {
                                label: 'Nr albumu',
                                name: 'searchAlbum',
                                value: searchAlbum,
                                type: 'text',
                            },
                            {
                                label: 'Test',
                                name: 'searchTestId',
                                value: searchTestId,
                                type: 'select',
                                selectOptions: selectOptions,
                            },
                            {
                                label: 'Data początkowa',
                                name: 'startDate',
                                value: startDate,
                                type: 'date',
                            },
                            {
                                label: 'Data końcowa',
                                name: 'endDate',
                                value: endDate,
                                type: 'date',
                            },
                        ]}
                        resetField={resetField}
                        handleChange={handleSearch}
                        handleSearch={() => {
                            console.log('searching...')
                            updateResults()
                        }}
                        resetAll={handleResetAll}
                    />

                    <TableContainer component={Paper} variant="outlined">
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'default'
                                            }
                                            sortDirection={
                                                orderBy === headCell.id
                                                    ? order
                                                    : false
                                            }
                                        >
                                            {headCell.sortable ? (
                                                <TableSortLabel
                                                    active={
                                                        orderBy === headCell.id
                                                    }
                                                    direction={
                                                        orderBy === headCell.id
                                                            ? order
                                                            : 'asc'
                                                    }
                                                    onClick={createSortHandler(
                                                        headCell.id
                                                    )}
                                                >
                                                    {headCell.label}
                                                </TableSortLabel>
                                            ) : (
                                                headCell.label
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(
                                    quizData,
                                    getComparator(order, orderBy)
                                ).map((row, i) => (
                                    <TableRow
                                        key={`${row.quiz_id}_${row.title}-${row.fill_time}`}
                                    >
                                        <TableCell align="right" padding="none">
                                            {row.quiz_id}
                                        </TableCell>
                                        <TableCell>{row.quiz_name}</TableCell>
                                        <TableCell>
                                            <MuiPickersUtilsProvider
                                                libInstance={moment}
                                                utils={MomentUtils}
                                                locale={'pl'}
                                            >
                                                <DateTimePicker
                                                    variant="dialog"
                                                    cancelLabel="Anuluj"
                                                    showTodayButton
                                                    todayLabel="Dzisiejsza data"
                                                    ampm={false}
                                                    value={row.fill_time}
                                                    onChange={handleDateChange(
                                                        row.quiz_result_id
                                                    )}
                                                    labelFunc={date =>
                                                        moment(
                                                            date,
                                                            moment.ISO_8601
                                                        ).format('LLL')
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell padding="none" align="right">
                                            {row.user_album_number}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.quiz_result_points}
                                        </TableCell>
                                        <TableCell align="right">
                                            <MaterialLink
                                                color="primary"
                                                component={Link}
                                                to={
                                                    '/static/' +
                                                    row.link_to_rapport
                                                }
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                Link
                                            </MaterialLink>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    generateNewReport(
                                                        row.quiz_id,
                                                        row.user_id
                                                    )
                                                }}
                                            >
                                                Zaktualizuj
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {maxPage > 1 && (
                        <div className={classes.pagination}>
                            <Pagination
                                count={maxPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className={classes.skeletonContainer}>
                    <Skeleton variant="rect" height={80} />
                    <Skeleton variant="rect" height={450} />
                </div>
            )}
        </React.Fragment>
    )
}

export default TestsResults
