import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { Button } from 'components/buttons/Button'
import Heading from 'components/typography/Heading'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Api } from 'services/api'
import { AuthApiProvider } from 'services/auth'
import { Form, Heading2 } from './components'
import PasswordInput from './components/PasswordInput'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        margin: '50px auto',
        width: props => (props.normalScreen ? '50vw' : '100%'),
    },
    form: {
        width: '50vw',
        margin: '0 auto',
    },
    login: {
        marginTop: '50px',
    },
    alert: {
        marginTop: 15,
    },
}))

function ResetPasswordView() {
    const theme = useTheme()
    const normalScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles({ normalScreen })
    const history = useHistory()
    const { userHash } = useParams()
    const [password, setPassword] = useState('')
    const [validationError, setValidationError] = useState('')

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handlePasswordChange = _ => e => {
        setPassword(e.target.value)
    }

    function handleValidation() {
        let err = ''
        if (password === '') {
            err = 'Podaj nowe hasło'
        } else {
            err = ''
        }
        setValidationError(err)
        return err
    }

    async function handlePasswordReset(e) {
        e.preventDefault()
        const err = handleValidation()
        if (err === '') {
            try {
                await AuthApiProvider(Api()).updatePassword(password, userHash)
                setToastSeverity('success')
                setToastTitle('Sukces')
                setToastMessage(
                    'Jeśli taki adres email istnieje w naszej bazie, wysłaliśmy link do przywracania hasła.'
                )
                setToastOpen(true)
                setTimeout(() => {
                    history.push('/')
                }, 1500)
            } catch (e) {
                setToastSeverity('error')
                setToastMessage('Brak połączenia z serwerem.')
                setToastOpen(true)
                setTimeout(() => {
                    history.push('/')
                }, 1500)
            }
        } else {
            setToastSeverity('warning')
            setToastTitle('')
            setToastMessage(err)
            setToastOpen(true)
        }
    }

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    return (
        <React.Fragment>
            <Heading>Odzyskiwanie hasła</Heading>
            <Container className={classes.alert}>
                <Collapse in={toastOpen}>
                    <Alert onClose={handleToastClose} severity={toastSeverity}>
                        <AlertTitle>{toastTitle}</AlertTitle>
                        {toastMessage}
                    </Alert>
                </Collapse>
            </Container>
            <Container>
                <Grid
                    container
                    spacing={2}
                    justify="center"
                    alignItems="stretch"
                    className={classes.root}
                >
                    <Grid container item xs={12} spacing={2}>
                        <Heading2 align={'left'}>Podaj nowe hasło.</Heading2>
                    </Grid>

                    <Form className={classes.form}>
                        <PasswordInput
                            error={validationError}
                            onChange={handlePasswordChange}
                            width={'50vw'}
                        />

                        <Button onClick={handlePasswordReset}>
                            Przywróć hasło
                        </Button>
                    </Form>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default ResetPasswordView
