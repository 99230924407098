import QueryString from 'query-string'

const endpoints = {
    login: '/tokens',
    refreshToken: '/tokens/refresh',
    register: '/users',
    recoverPassword: '/users/password/reset',
    updatePassword: userHash => `/users/password/update/${userHash}`,
    updatePasswordStatic: '/users/password/updatestatic/',
    emailConfirm: '/users/email/confirm/',
}

export const AuthApiProvider = Api => {
    const login = async (username, password) =>
        await Api.post(
            endpoints.login,
            QueryString.stringify({
                username,
                password,
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        )

    const register = async data => await Api.post(endpoints.register, data)

    const recoverPassword = async email =>
        await Api.post(endpoints.recoverPassword, { email_address: email })

    const updatePassword = async (password, userHash) =>
        await Api.post(endpoints.updatePassword(userHash), {
            new_password: password,
        })

    const updatePasswordStatic = async passwords =>
        await Api.post(endpoints.updatePasswordStatic, passwords)

    const confirmEmail = async (email, album_number, userHash) =>
        await Api.post(
            endpoints.emailConfirm,
            {},
            {
                params: { hash: userHash, email, album_number },
            }
        )
    return {
        login,
        register,
        recoverPassword,
        updatePassword,
        updatePasswordStatic,
        confirmEmail,
    }
}
