import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Logo from 'components/logos/HeaderLogo'
import React from 'react'
import { HeaderMain, HeadTitle, Horizontal, NavChildren } from './styles'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import List from '@material-ui/core/List'
import EULogos from 'components/logos/EULogos'
import CookiesConsent from './CookiesConsent'
import ThemeMenu from 'components/navbar/ThemeMenu'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    header: {
        fontFamily: '"Bree Serif", serif',
        fontWeight: 'regular',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    headTitle: {},
    link: {
        margin: 'auto 10px auto 23px',
    },
    menuButton: {
        marginRight: theme.spacing(1),
        width: 80,
    },
    hide: {
        visibility: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
}))

function DrawerMenu({ classes, drawerState, handleDrawerClose, children }) {
    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={drawerState}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>{children}</List>
        </Drawer>
    )
}

function Navbar({ children }) {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:959px)')
    const [drawerState, setDrawerState] = React.useState(false)
    const handleDrawerOpen = () => {
        setDrawerState(true)
    }
    const handleDrawerClose = () => {
        setDrawerState(false)
    }

    return (
        <AppBar className={classes.header} position={'relative'}>
            <Horizontal>
                <EULogos />
                <CookiesConsent />
            </Horizontal>
            <Horizontal>
                <HeaderMain>
                    {isMobile && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(
                                classes.menuButton,
                                drawerState && classes.hide
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Link href="https://www.ateneum.edu.pl/">
                        <Logo />
                    </Link>
                    <Divider orientation={'vertical'} />
                    <Link
                        href="/"
                        underline="none"
                        color="inherit"
                        className={classes.link}
                    >
                        <HeadTitle>Bilans kompetencji</HeadTitle>
                    </Link>
                </HeaderMain>
                {isMobile ? (
                    <DrawerMenu
                        classes={classes}
                        drawerState={drawerState}
                        handleDrawerClose={handleDrawerClose}
                    >
                        {children}
                    </DrawerMenu>
                ) : (
                    <NavChildren>{children}</NavChildren>
                )}
                {!Boolean(children) && <ThemeMenu />}
            </Horizontal>
        </AppBar>
    )
}
export default Navbar
