import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import GridContainer from 'components/typography/GridContainer'
import { makeStyles } from '@material-ui/core/styles'
import MLink from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
    NavLink: {
        fontFamily: ['Bree Serif', 'Times New Roman', 'serif'].join(', '),
        color: theme.typography.h2.color,
        fontSize: '1.5rem',
        margin: 'auto',
        padding: '0 20px',
    },
}))

export function NavLink({ children, ...props }) {
    const classes = useStyles()
    return (
        <MLink className={classes.NavLink} component={RouterLink} {...props}>
            {children}
        </MLink>
    )
}

const Container = styled(GridContainer)`
    border-bottom: 7px solid
        ${props => (props.borderColor ? props.borderColor : 'blue')};
`

const Component = ({ borderColor, ...props }) => (
    <Container borderColor={borderColor}>
        <NavLink {...props} />
    </Container>
)

export const LinkContainer = styled.div`
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: stretch;
    @media (min-width: 960px) {
        margin-left: 10vw;
        flex-direction: row;
    }
`

export default Component
