import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { defaultTheme, highContrast } from './theme'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import update from 'immutability-helper'

import Navbar from 'components/navbar/index'
import Footer from 'components/footer/Footer'
/* user scenes */
import ResetPasswordView from 'scenes/auth/resetPassword'
import EmailConfirmView from 'scenes/auth/emailConfirm'
import RecoverPasswordView from 'scenes/auth/recoverPassword'
import LoginView from 'scenes/auth/login'
import RegisterView from 'scenes/auth/register'
import QuizView from 'scenes/quiz/index'
import CookiesView from 'scenes/cookies/index'
import HomeView from 'scenes/home/index'
import AccountView from 'scenes/home/account'
/* admin scenes */
import AdminHome from 'scenes/admin/index'

import PrivateRoute from 'PrivateRoute'
import AdminRoute from 'AdminRoute'
import { ThemeContext } from 'context/theme'
import { AuthContext } from 'context/auth'
import { useCookies } from 'react-cookie'
import GetNavForRole from 'components/navbar/GetNavForRole'

function App() {
    const [cookies, setCookie] = useCookies(['theme'])
    const savedTheme = cookies['theme']
    const [currentTheme, setCurrentTheme] = useState(
        savedTheme ? themeFromCookie(savedTheme) : defaultTheme
    )
    const [biggerFont, setBiggerFont] = useState(false)
    const toggleDefaultTheme = () => {
        setCurrentTheme(defaultTheme)
        setCookie({ theme: 'default', biggerFont })
    }
    document.getElementsByTagName('html')[0].style.fontSize = `${
        biggerFont ? 20 : 16
    }px`
    const toggleBiggerFont = () => {
        const BiggerFont = update(currentTheme, {
            typography: {
                fontSize: {
                    $set: currentTheme.typography.fontSize === 14 ? 20 : 14,
                },
            },
        })
        setBiggerFont(!biggerFont)
        setCurrentTheme(BiggerFont)
        console.log({ cookies })
        /* setCookie('theme', {
            theme: cookies['theme'].theme,
            biggerFont: !biggerFont,
        })*/
    }
    const toggleContrastTheme = () => {
        console.log({ highContrast })
        const BiggerFont = update(highContrast, {
            typography: {
                fontSize: {
                    $set: 20,
                },
            },
        })
        setCurrentTheme(BiggerFont)
        setCookie({ theme: 'default', biggerFont })
    }

    const handleTheme = variant => {
        switch (variant) {
            case 'contrast':
                toggleContrastTheme()
                break
            case 'biggerFont':
                toggleBiggerFont()
                break
            case 'default':
                toggleDefaultTheme()
                break
            default:
                toggleDefaultTheme()
        }
    }

    const themeFromCookie = cookie => {
        const { theme, biggerFont } = cookie
        setBiggerFont(biggerFont)
        if (theme === 'contrast') {
            return highContrast
        }
        return defaultTheme
    }

    const [authTokens, setAuthTokens] = useState(
        JSON.parse(localStorage.getItem('authTokens')) || null
    )

    const setTokens = data => {
        localStorage.setItem('authTokens', JSON.stringify(data))
        setAuthTokens(data)
    }

    return (
        <ThemeProvider theme={currentTheme}>
            <ThemeContext.Provider
                value={{ currentTheme, setCurrentTheme: handleTheme }}
            >
                <CssBaseline />
                <AuthContext.Provider
                    value={{ authTokens, setAuthTokens: setTokens }}
                >
                    <Router>
                        <Navbar>
                            {authTokens && (
                                <GetNavForRole
                                    tokens={authTokens}
                                    logout={() => setTokens(null)}
                                />
                            )}
                        </Navbar>

                        <Switch>
                            <Route
                                exact
                                path="/users/email_confirm"
                                component={EmailConfirmView}
                            />
                            <Route path="/cookies" component={CookiesView} />
                            <Route exact path="/login" component={LoginView} />
                            <Route
                                exact
                                path="/register"
                                component={RegisterView}
                            />

                            <Route
                                exact
                                path="/recover-password"
                                component={RecoverPasswordView}
                            />
                            <Route
                                path="/reset-password/:userHash"
                                component={ResetPasswordView}
                            />
                            {/* admin routes */}
                            <AdminRoute path="/admin" component={AdminHome} />
                            {/* private routes */}
                            <PrivateRoute
                                path="/test/:testId"
                                component={QuizView}
                            />
                            <PrivateRoute
                                path="/account"
                                component={AccountView}
                            />
                            <PrivateRoute path="/" component={HomeView} />
                        </Switch>
                    </Router>
                </AuthContext.Provider>
                <Footer />
            </ThemeContext.Provider>
        </ThemeProvider>
    )
}

export default App
