import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from 'context/theme'

const useStyles = makeStyles(theme => ({
    heading2: {
        color: theme.typography.h2.color,
        fontFamily: theme.typography.h2.fontFamily,
        margin: '32px 0',
        fontSize: '2.2rem',
        marginBottom: '55px',
        [theme.breakpoints.up('md')]: {
            textAlign: props => (props.align === 'right' ? 'right' : 'left'),
        },
    },
    paperContainer: {
        backgroundColor: theme.palette.secondary.main,
        minHeight: '100px',
        borderBottom: props =>
            props.theme.palette.type === 'dark' ? '1px solid #fff' : 'none',
        borderTop: props =>
            props.theme.palette.type === 'dark' ? '1px solid #fff' : 'none',
    },
    head: {
        color: theme.palette.secondary.contrastText,
        display: 'block',
        maxWidth: '80%',
        margin: 0,
        paddingTop: '20px',
        fontFamily: theme.typography.h2.fontFamily,
        fontSize: '3rem',
    },
}))

function Heading({ children, ...props }) {
    const { currentTheme } = useTheme()
    const classes = useStyles({ theme: currentTheme, ...props })
    return (
        <Paper className={classes.paperContainer} square={true} elevation={0}>
            <Container fixed>
                <h1 className={classes.head}>{children}</h1>
            </Container>
        </Paper>
    )
}

export default Heading
