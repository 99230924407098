import React from 'react'
import { Heading3 } from './components'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 400,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        paddingLeft: 16,
        paddingRight: 16,
    },

    listItem: {
        display: 'block',
        width: '100%',
        fontSize: '1.2rem !important',
        fontWeight: '500 !important',
    },
    selectedItem: {
        color: '#006940 !important',
        backgroundColor: 'rgba(139, 202, 167, 0.14) !important',
    },
}))

function OptionsList({ optionsList, handleOption, selectedId }) {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Heading3 className={classes.heading}>Opcje</Heading3>
            <div className={classes.root}>
                <List aria-label="lista działań">
                    {optionsList.map((option, i) => (
                        <ListItem
                            key={`option-${i}`}
                            onClick={() => handleOption(i)}
                            button
                            selected={selectedId === i}
                            classes={{ selected: classes.selectedItem }}
                        >
                            <ListItemText className={classes.listItem}>
                                {option.name}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </div>
        </React.Fragment>
    )
}

export default OptionsList
