import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'

const depToInt = {
    neo: 0,
    edu: 1,
    spo: 2,
}

const intToDep = ['neo', 'edu', 'spo']

const initialState = {
    neo: false,
    edu: false,
    spo: false,
}

function generateInitialState(passed) {
    let arr = passed || []
    return Object.keys(initialState).reduce(
        (deps, dep, i) => ({
            ...deps,
            [dep]: arr.includes(i),
        }),
        {}
    )
}

function DepartmentsChoice({ onChange, initialValue }) {
    const [state, setState] = React.useState(
        React.useMemo(() => generateInitialState(initialValue), [initialValue])
    )
    const [error, setError] = React.useState(false)
    const [initial, setInitial] = React.useState(true)
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked })
    }

    useEffect(() => {
        const departments = Object.keys(state)
            .filter(e => state[e])
            .map(e => depToInt[e])
        onChange('department_name')({
            target: { value: departments },
        })
    }, [state])

    function checkError() {
        if (initial === true) {
            setInitial(false)
        } else {
            const { neo, edu, spo } = state
            setError([neo, edu, spo].filter(v => v).length < 1)
        }
    }

    React.useEffect(checkError, [state])

    return (
        <Grid item xs={12}>
            <FormControl required fullWidth error={error} component="fieldset">
                <FormLabel component="legend">Wydział(y)</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={state.neo}
                                onChange={handleChange('neo')}
                                value={0}
                            />
                        }
                        label="Wydział Neofilologiczny"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={state.edu}
                                onChange={handleChange('edu')}
                                value={1}
                            />
                        }
                        label="Wydział Studiów Edukacyjnych"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={state.spo}
                                onChange={handleChange('spo')}
                                value={2}
                            />
                        }
                        label="Wydział Nauk Społecznych"
                    />
                </FormGroup>
                <FormHelperText>
                    {error
                        ? 'Wybierz co najmniej jeden wydział.'
                        : 'Wybierz wszystkie na których studiujesz.'}
                </FormHelperText>
            </FormControl>
        </Grid>
    )
}

export default DepartmentsChoice
