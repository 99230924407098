import styled from 'styled-components'

export const FooterContainer = styled.footer`
    font-family: 'Bree Serif', serif;
    font-weight: 'regular';
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    padding: 10px 20px 90px 20px;
    margin: 0;

    @media (min-height: 800px) {
        padding: 10px 10px 100px 10px;
    }
`
