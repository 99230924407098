import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import React from 'react'

const labels = {
    0: 'Wydział Neofilologiczny',
    1: 'Wydział Studiów Edukacyjnych',
    2: 'Wydział Nauk Społecznych',
}

function DepartmentsCheckbox({ handleDepChange, value }) {
    const handleChange = index => e => {
        const num = parseInt(index)

        if (e.target.checked && !value.includes(num)) {
            const arr = value.slice()
            arr.push(num)
            handleDepChange(arr.sort())
        } else {
            const arr = value.slice()
            arr.splice(num, 1)
            handleDepChange(arr)
        }
    }

    return (
        <Grid item xs={12}>
            <FormControl required fullWidth component="fieldset">
                <FormLabel component="legend">Wydział(y)</FormLabel>
                <FormGroup>
                    {Object.keys(labels).map((_, index) => (
                        <FormControlLabel
                            key={`dep-checkbox-${index}`}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={value.includes(index)}
                                    onChange={handleChange(index)}
                                    value={index}
                                />
                            }
                            label={labels[index]}
                        />
                    ))}
                </FormGroup>
                <FormHelperText>
                    Wybierz wszystkie dla których test ma być widoczny
                </FormHelperText>
            </FormControl>
        </Grid>
    )
}

function propsAreEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
}

const memoDeps = React.memo(DepartmentsCheckbox, propsAreEqual)
memoDeps.whyDidYouRender = true
export default memoDeps
