import React from 'react'
import { useParams } from 'react-router-dom'
import update from 'immutability-helper'
/* material */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Collapse from '@material-ui/core/Collapse'
import Skeleton from '@material-ui/lab/Skeleton'
/* custom */
import DepartmentsCheckbox from './components/DepartmentCheckbox'
import { Heading2 } from 'scenes/home/components'
import QuestionsList from './components/QuestionsList'
import AddQuestionButton from './components/AddQuestionButton'
import PanelDescription from './PanelDescription'
import PanelOptions from './PanelOptions'
import PanelSummary from './PanelSummary'
/* api */
import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { AdminApiProvider } from 'services/admin'
import {
    transformDataToSend,
    transformDataToState,
} from './utils/transformDataToSend'

const useStyles = makeStyles(theme => ({
    alert: {
        marginBottom: theme.spacing(2),
    },
    addQuestion: {
        marginTop: theme.spacing(2),
    },
    spacingBottom: {
        marginBottom: theme.spacing(2),
    },
    dualSkeleton: {
        display: 'grid',
        justifyItems: 'stretch',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))

function EditTest() {
    const classes = useStyles()
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)

    let { testId } = useParams()

    const [quiz_name, setQuizName] = React.useState('')
    const [final_date, setFinalDate] = React.useState(new Date())
    const [correlated, setCorrelated] = React.useState([])
    const [correlated_quiz_id, setCorrelatedId] = React.useState(null)

    const [available_for_group, setGroups] = React.useState([])

    const [messages, setMessages] = React.useState([
        {
            min_points: 0,
            message: '',
        },
    ])
    const [questions_to_draw, setQuestions] = React.useState(null)
    const [state, setState] = React.useState({
        count_of_questions_to_draw: null,
    })

    async function getTestForEditing() {
        try {
            const res = await AdminApiProvider(ApiContext).getExistingTest(
                testId
            )
            const s = transformDataToState(res.data.data.quiz)
            setMessages(s.messages)
            setQuestions(s.questions_to_draw)
            setQuizName(s.quiz_name)
            setFinalDate(s.final_date)
            setGroups(s.available_for_group)
            setCorrelatedId(s.correlated_quiz_id)
        } catch (e) {
            setToastSeverity('error')
            setToastMessage('Wystąpił błąd.')
            setToastOpen(true)
        }
    }

    async function populateCorrelated() {
        try {
            const res = await AdminApiProvider(ApiContext).getTestHeaders()
            setCorrelated(
                res.data
                    .filter(e => e.quiz_id !== parseInt(testId))
                    .sort((a, b) => {
                        if (a.quiz_id > b.quiz_id) return 1
                        if (b.quiz_id > a.quiz_id) return -1
                        return 0
                    })
                    .map(e => ({
                        id: e.quiz_id,
                        text: `${e.quiz_id}: ${e.quiz_header}`,
                    }))
            )
        } catch (e) {
            setToastSeverity('error')
            setToastMessage('Wystąpił błąd.')
            setToastOpen(true)
        }
    }

    async function sendEditedTest() {
        try {
            await AdminApiProvider(ApiContext).updateExistingTest(
                testId,
                transformDataToSend({
                    ...state,
                    questions_to_draw,
                    quiz_name,
                    final_date,
                    correlated_quiz_id,
                    available_for_group,
                    messages,
                })
            )
            setToastSeverity('success')
            setToastTitle('Sukces')
            setToastMessage('Test edytowano pomyślnie!')
            setToastOpen(true)
        } catch (e) {
            setToastSeverity('error')
            setToastMessage('Wystąpił błąd.')
            setToastOpen(true)
        }
    }

    React.useEffect(() => {
        populateCorrelated()
        getTestForEditing()
    }, [])

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    const handleDepChange = dep => {
        setGroups(dep)
    }

    const addNewQuestion = () => {
        const q = update(questions_to_draw, {
            $push: [
                {
                    question_tittle: '',
                    question_entities: [
                        {
                            question_id: 0,
                            question_header: '',
                            question_value: 0,
                        },
                    ],
                },
            ],
        })
        setQuestions(q)
    }

    const handleQuestion = i => question => {
        const res = update(questions_to_draw, {
            [i]: { $set: question },
        })
        setQuestions(res)
    }

    const deleteQuestion = i => e => {
        const res = update(questions_to_draw, { $splice: [[i, 1]] })
        setQuestions(res)
    }

    const handleStateChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleMessagesChange = i => e => {
        const currentMessage = messages[i]
        const name = e.target.name
        const newMessages = update(messages, {
            [i]: {
                $set: {
                    ...currentMessage,
                    [name]:
                        name === 'min_points'
                            ? parseInt(e.target.value)
                            : e.target.value,
                },
            },
        })
        setMessages(newMessages)
    }

    const handleAddMessage = () => {
        setMessages(
            update(messages, {
                $push: [
                    {
                        min_points: 0,
                        message: '',
                    },
                ],
            })
        )
    }

    const handleDeleteMessage = i => () => {
        setMessages(update(messages, { $splice: [[i, 1]] }))
    }

    return (
        <React.Fragment>
            <Heading2>Edytuj test</Heading2>
            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>

            {questions_to_draw !== null ? (
                <React.Fragment>
                    <Grid
                        container
                        justify="space-between"
                        spacing={3}
                        className={classes.spacingBottom}
                    >
                        <PanelDescription
                            quiz_name={quiz_name}
                            setQuizName={setQuizName}
                            messages={messages}
                            handleMessagesChange={handleMessagesChange}
                            addMessage={handleAddMessage}
                            deleteMessage={handleDeleteMessage}
                            final_date={final_date}
                            setFinalDate={setFinalDate}
                        />
                        <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            justify="space-between"
                            alignItems="center"
                        >
                            <PanelOptions
                                correlated_quiz_id={correlated_quiz_id}
                                correlated={correlated}
                                setCorrelatedId={setCorrelatedId}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DepartmentsCheckbox
                                value={available_for_group}
                                handleDepChange={handleDepChange}
                            />
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={6}>
                            <PanelSummary
                                questions_length={questions_to_draw.length}
                                count_of_questions_to_draw={
                                    state.count_of_questions_to_draw
                                }
                                send={sendEditedTest}
                                handleStateChange={handleStateChange}
                                commitLabel={'Edytuj test'}
                            />
                        </Grid>
                    </Grid>

                    <QuestionsList
                        questions_to_draw={questions_to_draw}
                        deleteQuestion={deleteQuestion}
                        handleQuestion={handleQuestion}
                    />

                    <AddQuestionButton
                        className={classes.addQuestion}
                        onClick={addNewQuestion}
                    />
                </React.Fragment>
            ) : (
                <div>
                    <Skeleton
                        variant="rect"
                        height={70}
                        className={classes.spacingBottom}
                    />
                    <Skeleton
                        variant="rect"
                        height={148}
                        className={classes.spacingBottom}
                    />
                    <div className={classes.dualSkeleton}>
                        <Skeleton variant="rect" height={148} />
                        <Skeleton variant="rect" height={148} />
                    </div>
                    <Skeleton variant="rect" height={260} />
                </div>
            )}
        </React.Fragment>
    )
}

export default EditTest
