import React from 'react'
import styled from 'styled-components'
import logo_fe from './logo_fundusze_europejskie.jpg'
import logo_rp from './logo_RP.png'
import logo_ue from './logo_UE.jpg'

const FlagContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    background-color: white;
    border-top: 1px ${props => (props.bottomOfPage ? '#fff' : '#ddd')} solid;

    & > div {
        max-width: 200px;
        height: 100%;
    }
    & > div > img {
        display: block;
        width: 100%;
        height: auto;
    }
`

const EULogos = props => {
    const [bottom, setBottom] = React.useState(false)
    const handleBottomChange = () => {
        const bottomOfPage =
            window.innerHeight + window.scrollY + 100 >=
            document.body.offsetHeight
        if (bottomOfPage !== bottom) {
            setBottom(bottomOfPage)
        }
    }
    window.addEventListener('scroll', handleBottomChange)
    return (
        <FlagContainer bottomOfPage={bottom}>
            <div>
                <img src={logo_fe}></img>
            </div>
            <div>
                <img src={logo_rp}></img>
            </div>
            <div>
                <img src={logo_ue}></img>
            </div>
        </FlagContainer>
    )
}
export default EULogos
