import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: props => (props.width ? props.width : '100%'),
    },
    field: {
        width: props => (props.width ? props.width : '100%'),
    },
    left: {
        paddingRight: 10,
    },
}))

const isStrongPassword = pass => {
    const re = new RegExp(
        /^((?=.*[0-9])(?=.*[a-z])(?=.*[\W\s\t\0])(?=.*[A-Z]))([^\n\t\r\s]{8,})$/gm
    )
    return re.test(pass)
}

export default function PasswordInput(props) {
    const classes = useStyles({ width: props.width })

    const [state, setState] = React.useState({
        password: '',
        password_repeat: '',
    })
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value })
        props.onChange(name)(event)
    }
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        if (state.password !== state.password_repeat) {
            setError('Hasła muszą być identyczne.')
        } else if (state.password !== '' && !isStrongPassword(state.password)) {
            setError(
                'Hasło musi zawierać conajmniej 8 znaków oraz składać się z conajmniej 1 małej litery, 1 wielkiej litery, 1 cyfry i 1 znaku specjalnego.'
            )
        } else {
            setError(false)
        }
    }, [state])

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6} className={classes.root}>
                <TextField
                    fullWidth
                    id="password"
                    type="password"
                    label="Hasło"
                    error={props.error || error ? true : false}
                    helperText={error || 'Silne hasło.'}
                    value={state.password}
                    onChange={handleChange('password')}
                    className={`${classes.field} ${classes.left}`}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    id="password_repeat"
                    type="password"
                    label="Hasło"
                    error={props.error || error ? true : false}
                    value={state.password_repeat}
                    onChange={handleChange('password_repeat')}
                    className={classes.field}
                />
            </Grid>
        </React.Fragment>
    )
}
