import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

/* select */
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

/* date */
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as moment from 'moment'
import 'moment/locale/pl'

const useStyles = makeStyles(theme => ({
    searchBar: { marginBottom: theme.spacing(2) },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

function SearchFields({
    fields,
    resetField,
    handleChange,
    handleSearch,
    resetAll,
}) {
    const classes = useStyles()

    return (
        <Grid
            container
            spacing={2}
            alignContent="center"
            alignItems="center"
            className={classes.searchBar}
        >
            {fields.map((field, i) => {
                if (field.type === 'text') {
                    return (
                        <Grid item xs={6} key={'search-field-' + i}>
                            <TextField
                                label={field.label}
                                name={field.name}
                                className={classes.searchBar}
                                value={field.value}
                                onChange={handleChange}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={`reset pola wyszukiwania ${field.label.toLowerCase()}`}
                                                onClick={() =>
                                                    resetField(field.name)
                                                }
                                            >
                                                {field.value !== '' && (
                                                    <CloseIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    )
                } else if (field.type === 'date') {
                    return (
                        <Grid item xs={6} key={'search-field-' + i}>
                            <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                                locale={'pl'}
                            >
                                <DateTimePicker
                                    variant="dialog"
                                    cancelLabel="Anuluj"
                                    ampm={false}
                                    label={field.label}
                                    name={field.name}
                                    className={classes.searchBar}
                                    value={
                                        field.value === ''
                                            ? field.name === 'startDate'
                                                ? new Date('2010-01-01')
                                                : new Date()
                                            : field.value
                                    }
                                    onChange={date =>
                                        handleChange({
                                            target: {
                                                value: date,
                                                name: field.name,
                                            },
                                        })
                                    }
                                    fullWidth
                                    clearable
                                    clearLabel="Usuń"
                                    labelFunc={date =>
                                        moment(date, moment.ISO_8601).format(
                                            'LLL'
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )
                } else if (field.type === 'select') {
                    return (
                        <Grid item xs={6} key={'search-field-' + i}>
                            <FormControl fullWidth>
                                <InputLabel id={`select-${field.name}-label`}>
                                    {field.label}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId={`select-${field.name}-label`}
                                    value={field.value}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: field.name,
                                        id: field.name,
                                    }}
                                    className={classes.searchBar}
                                >
                                    <MenuItem aria-label="None" value="" />>
                                    {field.selectOptions.map(o => (
                                        <MenuItem
                                            key={'search-opt-' + o.id + o.text}
                                            value={o.id}
                                        >
                                            {o.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )
                }
            })}
            <Grid item xs={3}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        handleSearch()
                    }}
                >
                    Wyszukaj
                </Button>
            </Grid>
            {resetAll && (
                <Grid item xs={3}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            resetAll()
                        }}
                    >
                        Wyczyść
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SearchFields
