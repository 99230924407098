import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    heading2: {
        color: theme.typography.h2.color,
        fontFamily: theme.typography.h2.fontFamily,
        margin: '32px 0',
        fontSize: '2.2rem',
        marginBottom: '55px',
        [theme.breakpoints.up('md')]: {
            textAlign: props => (props.align === 'right' ? 'right' : 'left'),
        },
    },
}))

export function Heading2({ children, ...props }) {
    const classes = useStyles(props)
    return <h2 className={classes.heading2}>{children}</h2>
}

export const Heading3 = styled.h3`
    font-family: 'Bree Serif', 'Times New Roman', serif;
    font-size: 2rem;
    color: #4c4c4c;
`

export const StyledLink = styled(Link)`
    font-size: 1.5rem;
    margin: 4px 4px 4px 15px;
    display: inline-block;
    width: 200px;
`
export const ButtonContainer = styled.div`
    display: grid;
    grid: auto-flow / 1fr;
    justify-content: space-evenly;
    @media (min-width: 600px) {
        grid: 1fr / auto-flow;
        justify-content: stretch;
        & > * {
            margin: 0 10px;
        }
    }
`
export const GridContainer = styled.div`
    width: 100%;

    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
        'sidebar'
        'main';
    min-height: 600px;
    grid-template-columns: 1fr;
    @media (min-width: 600px) {
        grid-template-columns: 30% 20px auto;
        grid-template-areas: 'sidebar divider main';
        min-height: calc(600px + 10vh);
    }
    @media (min-width: 960px) {
        grid-template-columns: auto 20px 80%;
    }
`
export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: sidebar;
`
export const DividerContainer = styled.div`
    display: block;
    grid-area: divider;
    padding-top: 50px;
    padding-bottom: 50px;
`
export const Main = styled.div`
    grid-area: main;
    @media (min-width: 960px) {
        margin: 0 50px;
    }
`
