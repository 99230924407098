import React, { useState } from 'react'
import update from 'immutability-helper'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
        padding: '15px 30px',
        margin: theme.spacing(4, 0),
        position: 'relative',
    },
    titleContainer: {
        marginTop: 30,
    },
    title: {
        paddingBottom: 30,
    },
    delQuestion: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
}))
function NewQuestionComponent({
    question_tittle,
    question_entities,
    handleQuestion,
    deleteQuestion,
    keyName,
    style,
}) {
    const classes = useStyles()
    const letters = ['A', 'B', 'C', 'D', 'E']
    const [canAddNewAns, setCanAddNewAns] = useState(true)

    const handleTitleChange = event => {
        //setTitle(event.target.value)
        handleQuestion({
            question_entities,
            question_tittle: event.target.value,
        })
    }

    const handleAnswersChange = i => name => event => {
        const res = update(question_entities, {
            [i]: {
                [name]: {
                    $set:
                        name === 'question_value'
                            ? parseInt(event.target.value)
                            : event.target.value,
                },
            },
        })
        handleQuestion({ question_entities: res, question_tittle })
        //setQuestions(res)
    }

    function addNewAnswer() {
        if (canAddNewAns) {
            const newAns = update(question_entities, {
                $push: [
                    {
                        question_id: question_entities.length,
                        question_header: '',
                        question_value: 0,
                    },
                ],
            })

            //setQuestions(newAns)
            handleQuestion({ question_entities: newAns, question_tittle })
            if (newAns.length === letters.length) {
                setCanAddNewAns(false)
            }
        }
    }

    const deleteAnswer = answerIdx => event => {
        const res = update(question_entities, { $splice: [[answerIdx, 1]] })
        handleQuestion({ question_entities: res, question_tittle })
    }

    return (
        <Card className={classes.card} styles={style} elevation={2}>
            <CardContent>
                <Grid container justify="space-between" spacing={1}>
                    <IconButton
                        aria-label="usun pytanie"
                        onClick={deleteQuestion}
                        className={classes.delQuestion}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            className={classes.title}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                key={`question-title-${keyName}`}
                                label="Podaj pytanie"
                                value={question_tittle}
                                onChange={handleTitleChange}
                            />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container justify="space-between" spacing={1}>
                    {question_entities.map((answer, i) => (
                        <React.Fragment key={`ques-${keyName}-ans-${i}`}>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    value={answer.question_header}
                                    label={`Odpowiedź ${letters[i]}`}
                                    onChange={handleAnswersChange(i)(
                                        'question_header'
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    label="Punkty"
                                    type="number"
                                    value={answer.question_value}
                                    min="0"
                                    max="5"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleAnswersChange(i)(
                                        'question_value'
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="usun odpowiedz"
                                    onClick={deleteAnswer(i)}
                                >
                                    <DeleteIcon></DeleteIcon>
                                </IconButton>
                            </Grid>
                        </React.Fragment>
                    ))}
                    {canAddNewAns && (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={addNewAnswer}
                            >
                                <AddOutlinedIcon />
                                Dodaj kolejną odpowiedź
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default React.memo(NewQuestionComponent)
