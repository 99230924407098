import React from 'react'
import { CookiePrompt } from './styles'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'

import { useCookies } from 'react-cookie'
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#009d69',
    },
    a: {
        color: '#ffffff',
        fontWeight: '800',
    },
}))

function CookiesConsent() {
    const [cookies, setCookie] = useCookies(['cookie-agreement'])
    const [open, setOpen] = React.useState(false)
    const classes = useStyles()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setCookie('cookie-agreement', true)
        setOpen(false)
    }

    function checkCookie() {
        const agree = cookies['cookie-agreement']
        if (agree === undefined) {
            setOpen(true)
        }
    }

    React.useEffect(() => {
        checkCookie()
    }, [])

    return (
        <Snackbar
            ContentProps={{
                classes: {
                    root: classes.root,
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
            message={
                <CookiePrompt>
                    <div>Powiadomienie o ciasteczkach</div>
                    <p>
                        W celu lepszego dostosowywania treści i struktury
                        serwisu do Państwa potrzeb używamy mechanizmu cookies.
                        Aby dowiedzieć się więcej na temat mechanizmu cookies na
                        tej stronie{' '}
                        <Link to="/cookies" className={classes.a}>
                            kliknij tutaj
                        </Link>
                        .
                    </p>
                </CookiePrompt>
            }
            action={
                <React.Fragment>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={handleClose}
                    >
                        Zgadzam się
                    </Button>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

function CookieHOC() {
    const [cookies] = useCookies(['cookie-agreement'])

    if (cookies['cookie-agreement'] === true) {
        return <></>
    } else {
        return <CookiesConsent />
    }
}
export default CookieHOC
