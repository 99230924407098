import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Heading3 } from 'scenes/home/components'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 400,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        paddingLeft: 16,
        paddingRight: 16,
        color: theme.typography.h2.color,
    },
    listItem: {
        display: 'block',
        width: '100%',
        fontSize: '1.2rem !important',
        fontWeight: '500 !important',
        color: theme.palette.text.primary,
    },
    selectedItem: {
        backgroundColor:
            theme.palette.type === 'dark'
                ? `${theme.palette.primary.main} !important`
                : 'rgba(139, 202, 167, 0.14) !important',
        '& span': {
            color:
                theme.palette.type === 'dark'
                    ? '#000000 !important'
                    : '#006940 !important',
        },
    },
    links: {
        color: 'initial',
        textDecoration: 'none',
    },
}))

function OptionsList({ options }) {
    const classes = useStyles()
    const [selectedId, setSelectedId] = React.useState(null)
    return (
        <React.Fragment>
            <Heading3 className={classes.heading}>Opcje</Heading3>
            <div className={classes.root}>
                <List aria-label="lista opcji">
                    {options.map((option, i) => (
                        <NavLink
                            key={`option-${i}`}
                            to={option.link}
                            isActive={(match, location) => {
                                if (!match) {
                                    return false
                                }
                                setSelectedId(i)
                                return true
                            }}
                            className={classes.links}
                        >
                            <ListItem
                                button
                                selected={selectedId === i}
                                classes={{ selected: classes.selectedItem }}
                            >
                                <ListItemText className={classes.listItem}>
                                    {option.text}
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                    ))}
                </List>
            </div>
        </React.Fragment>
    )
}

export default OptionsList
