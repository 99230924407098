import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from 'context/theme'

function ThemeMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const { setCurrentTheme } = useTheme()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuItem = (event, theme) => {
        setCurrentTheme(theme)
        setAnchorEl(null)
    }
    const handleClose = theme => () => {
        setAnchorEl(null)
    }

    return (
        <React.Fragment>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                Ułatwienia dostępności
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={e => handleMenuItem(e, 'default')}>
                    Domyślny motyw
                </MenuItem>
                <MenuItem onClick={e => handleMenuItem(e, 'contrast')}>
                    Wysoki kontrast
                </MenuItem>
                <MenuItem onClick={e => handleMenuItem(e, 'biggerFont')}>
                    Włącz/wyłącz dużą czcionkę
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default ThemeMenu
