import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import PasswordInput from './PasswordInput'
import RegisterContext from '../useRegister'

function BasicInputs(props) {
    return (
        <RegisterContext.Consumer>
            {({ state, errors, handleRegisterChange }) => (
                <React.Fragment>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="email_address"
                            type="email"
                            label="Adres email"
                            error={errors.email_address !== ''}
                            helperText={
                                errors.email_address.length !== 0
                                    ? errors.email_address
                                    : 'Używany do logowania, oraz przesyłania wyników.'
                            }
                            value={state.email_address}
                            onChange={handleRegisterChange('email_address')}
                        />
                    </Grid>
                    <PasswordInput onChange={handleRegisterChange} />
                </React.Fragment>
            )}
        </RegisterContext.Consumer>
    )
}

function BasicInputsAreEqual(prevInputs, nextInputs) {
    return (
        prevInputs.state === nextInputs.state &&
        prevInputs.errors === nextInputs.errors
    )
}
const MemoBasicInputs = React.memo(BasicInputs, BasicInputsAreEqual)
export default MemoBasicInputs
