import MaterialButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Switch from '@material-ui/core/Switch'
import Chip from '@material-ui/core/Chip'

import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import { Link } from 'react-router-dom'
import { Heading2 } from 'scenes/home/components'
import * as moment from 'moment'
import 'moment/locale/pl'

import { useAuth } from 'context/auth'
import { Api } from 'services/api'
import { AdminApiProvider } from 'services/admin'

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
        backgroundColor: '#eed433',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            width: 'max-content',
            margin: `${theme.spacing(2)}px auto`,
        },
    },
    table: {
        minWidth: 650,
    },
    skeletonContainer: {
        height: 640,
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    deps: {
        '& > *': {
            color: theme.palette.text.primary,
            margin: theme.spacing(0.5, 1, 0, 0),
            border:
                theme.palette.type === 'dark'
                    ? `1px solid ${theme.palette.text.disabled}`
                    : 'none',
        },
    },
    alert: {
        margin: `${theme.spacing(2)}px auto`,
    },
}))
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

const headCells = [
    {
        id: 'quiz_id',
        label: 'ID testu',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'quiz_name',
        label: 'Tytuł',
        numeric: false,
        disablePadding: false,
    },
    {
        id: 'final_date',
        label: 'Data zamknięcia testu',
        numeric: false,
    },
    {
        id: 'count_of_questions_to_draw',
        label: 'Ilość pytań',
        numeric: false,
    },

    {
        id: 'available_for_group',
        label: 'Wydziały',
        numeric: true,
        disablePadding: false,
    },
    {
        id: 'is_active',
        label: 'Test aktywny',
        numeric: false,
        disablePadding: false,
    },
    {
        label: 'Opcje',
        numeric: false,
        disablePadding: false,
    },
    {
        label: 'Przypomnienia na email',
        numeric: false,
        disablePadding: false,
    },
]

function TestsTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props

    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }
    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

function TestsTable({
    rows,
    classes,
    order,
    orderBy,
    onRequestSort,
    maxPage,
    handlePageChange,
    handleActiveChange,
    handleCopyQuiz,
    sendQuizReminder,
}) {
    return (
        <React.Fragment>
            <TableContainer component={Paper} variant="outlined">
                <Table className={classes.table} aria-label="simple table">
                    <TestsTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map(
                            (row, i) => (
                                <TableRow key={row.quiz_id}>
                                    <TableCell align="right">
                                        {row.quiz_id}
                                    </TableCell>
                                    <TableCell>{row.quiz_name}</TableCell>
                                    <TableCell>
                                        {moment(
                                            row.final_date,
                                            moment.ISO_8601
                                        ).format('LLL')}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className={classes.deps}
                                    >
                                        {row.available_for_group.map(
                                            (dep, k) => (
                                                <Chip
                                                    key={`chip-${i}-${k}`}
                                                    size="small"
                                                    color="secondary"
                                                    label={dep}
                                                />
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.count_of_questions_to_draw}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Switch
                                            checked={row.is_active}
                                            onChange={handleActiveChange(
                                                row.quiz_id
                                            )}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <MaterialButton
                                            color="primary"
                                            component={Link}
                                            to={`/admin/tests/${row.quiz_id}/edit`}
                                        >
                                            Edytuj
                                        </MaterialButton>
                                        <MaterialButton
                                            color="primary"
                                            onClick={handleCopyQuiz(
                                                row.quiz_id
                                            )}
                                        >
                                            Kopiuj
                                        </MaterialButton>
                                    </TableCell>
                                    <TableCell align="right">
                                        <MaterialButton
                                            color="primary"
                                            onClick={sendQuizReminder(
                                                row.quiz_id
                                            )}
                                        >
                                            Wyślij
                                        </MaterialButton>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

function mapDepartmentNumToName(department) {
    switch (department) {
        case 0:
            return 'Neofilologiczny'
        case 1:
            return 'Studiów Edukacyjnych'
        case 2:
            return 'Nauk Społecznych'
        default:
            return ''
    }
}

function AllTests() {
    const classes = useStyles()
    const [testList, setTestList] = React.useState(null)
    const [page, setPage] = React.useState(1)
    const [maxPage, setMaxPage] = React.useState(4)
    const [limit] = React.useState(10)
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('quiz_id')
    const { authTokens, setAuthTokens } = useAuth()
    const ApiContext = Api(authTokens, setAuthTokens)

    const [toastOpen, setToastOpen] = React.useState(false)
    const [toastSeverity, setToastSeverity] = React.useState('success')
    const [toastMessage, setToastMessage] = React.useState('')
    const [toastTitle, setToastTitle] = React.useState('')

    const populateTestList = async () => {
        try {
            const skip = limit * (page - 1)
            const res = await AdminApiProvider(
                ApiContext
            ).getAvailableTestsList(skip, limit, authTokens)
            let s = res.data.data.map(el => ({
                ...el,
                available_for_group: el.available_for_group.map(
                    mapDepartmentNumToName
                ),
            }))
            setMaxPage(parseInt((res.data.count + limit - 1) / limit))
            setTestList(s)
        } catch (e) {
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }
    const handleActiveChange = quiz_id => async (event, value) => {
        try {
            const res = await AdminApiProvider(ApiContext).switchIsActive(
                quiz_id
            )
            let a = testList.slice()
            a.forEach(t => {
                if (t.quiz_id === quiz_id) {
                    t.is_active = res.data
                }
            })
            setTestList(a)
        } catch (e) {
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    const handleCopyQuiz = quiz_id => async (event, value) => {
        try {
            let res = await AdminApiProvider(ApiContext).getExistingTest(
                quiz_id
            )
            res = res.data.data.quiz
            delete res['id']
            console.log({ ...res, quiz_name: `Kopia ${res.quiz_name}` })
            await AdminApiProvider(ApiContext).createNewTest({
                ...res,
                quiz_name: `Kopia ${res.quiz_name}`,
                available_for_group: [],
            })
            populateTestList()
            setToastSeverity('success')
            setToastTitle('Sukces')
            setToastMessage('Test utworzono pomyślnie!')
            setToastOpen(true)
        } catch (e) {
            console.log({ e })
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const handleToastClose = (event, reason) => {
        setToastOpen(false)
    }

    const sendQuizReminder = quiz_id => async (event, value) => {
        try {
            await AdminApiProvider(ApiContext).sendQuizReminder(quiz_id)
        } catch (e) {
            console.log(e)
            setToastSeverity('error')
            if (e.response) {
                setToastTitle('Błąd')
                setToastMessage(
                    'Wystąpił nieoczekiwany bład po stronie serwera.'
                )
            } else {
                setToastMessage('Brak połączenia z serwerem.')
            }
            setToastOpen(true)
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    React.useEffect(() => {
        populateTestList()
    }, [page])

    return (
        <React.Fragment>
            <Heading2>Wszystkie testy</Heading2>
            <Collapse in={toastOpen} className={classes.alert}>
                <Alert onClose={handleToastClose} severity={toastSeverity}>
                    <AlertTitle>{toastTitle}</AlertTitle>
                    {toastMessage}
                </Alert>
            </Collapse>

            {testList ? (
                <TestsTable
                    classes={classes}
                    rows={testList}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    maxPage={maxPage}
                    handlePageChange={handlePageChange}
                    handleActiveChange={handleActiveChange}
                    handleCopyQuiz={handleCopyQuiz}
                    sendQuizReminder={sendQuizReminder}
                />
            ) : (
                <div className={classes.skeletonContainer}>
                    <Skeleton variant="rect" height={80} />
                    <Skeleton variant="rect" height={450} />
                </div>
            )}
        </React.Fragment>
    )
}

export default AllTests
