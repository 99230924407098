import React from 'react'
import { Heading3 } from './components'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        paddingLeft: 16,
        paddingRight: 16,
        color: theme.typography.h2.color,
    },

    listItem: {
        display: 'block',
        width: '100%',
        fontSize: '1.2rem !important',
        fontWeight: '500 !important',
        color: theme.palette.text.primary,
    },
    selectedItem: {
        backgroundColor:
            theme.palette.type === 'dark'
                ? `${theme.palette.primary.main} !important`
                : 'rgba(139, 202, 167, 0.14) !important',
        '& span': {
            color:
                theme.palette.type === 'dark'
                    ? '#000000 !important'
                    : '#006940 !important',
        },
    },
}))

function QuizList({ testList, handleTest, selectedId }) {
    const classes = useStyles()
    return (
        <React.Fragment>
            {testList.length !== 0 && (
                <Heading3 className={classes.heading}>Twoje testy</Heading3>
            )}
            <div className={classes.root}>
                <List aria-label="lista twoich quizów">
                    {testList.map((test, i) => (
                        <ListItem
                            key={`test-${i}-${test.id}`}
                            onClick={() => handleTest(test.id)}
                            button
                            selected={selectedId === test.id}
                            classes={{ selected: classes.selectedItem }}
                        >
                            <ListItemText className={classes.listItem}>
                                {test.quiz_name}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </div>
        </React.Fragment>
    )
}

export default QuizList
