import React from 'react'
import NewQuestion from './NewQuestion'
const isEqual = require('react-fast-compare')

function QuestionsList({ questions_to_draw, handleQuestion, deleteQuestion }) {
    return questions_to_draw.map((question, i) => (
        <NewQuestion
            key={`question_add-${i}-grid`}
            position={i}
            question_tittle={question.question_tittle}
            question_entities={question.question_entities}
            deleteQuestion={deleteQuestion(i)}
            handleQuestion={handleQuestion(i)}
        />
    ))
}
function arePropsEqual(p, n) {
    return isEqual(p.questions_to_draw, n.questions_to_draw)
}
export default React.memo(QuestionsList, arePropsEqual)
